// utils.js

export const createSlug = (title) => {
    if (title == null) {
        return '';
    }
    return title.replace(/\s+/g, '-').replace(/[^\w-]+/g, '').toLowerCase();
};

export const formatDateFuture = (date, t) => {
    const now = new Date();
    const targetDate = new Date(date);
    const diffInMilliseconds = targetDate - now;

    if (diffInMilliseconds <= 0) {
        // Return the exact date if it’s in the past
        return targetDate.toLocaleDateString(t('date_locale'), { day: '2-digit', month: '2-digit', year: 'numeric' });
    }

    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const days = Math.floor(hours / 24);

    if (hours < 1) {
        return `${minutes}${t('date_minute_short')}`; // Less than 1 hour, display minutes
    } else if (hours < 12) {
        return `${hours}${t('date_hour_short')} ${minutes}${t('date_minute_short')}`; // Less than 6 hours, display hh:mm
    } else if (days < 1) {
        return `${hours}${t('date_hour_short')}`; // Less than 1 day, display hours
    } else if (days <= 10) {
        return `${days}${t('date_day_short')}`; // Less than 10 days, display days
    } else {
        // Default format for dates more than 10 days away
        return targetDate.toLocaleDateString(t('date_locale'), { day: '2-digit', month: '2-digit', year: 'numeric' });
    }
};

export const formatDatePast = (date, t) => {
    const now = new Date();
    const targetDate = new Date(date);
    const diffInMilliseconds = targetDate - now;

    // Calculate absolute values for future and past
    const isFuture = diffInMilliseconds > 0;
    const absDiff = Math.abs(diffInMilliseconds);

    const hours = Math.floor(absDiff / (1000 * 60 * 60));
    const minutes = Math.floor((absDiff % (1000 * 60 * 60)) / (1000 * 60));
    const days = Math.floor(hours / 24);

    if (isFuture) {
        // Future dates
        if (hours < 1) {
            return `${t('date_in')} ${minutes}${t('date_minute_short')}`;
        } else if (hours < 12) {
            return `${t('date_in')} ${hours}${t('date_hour_short')} ${minutes}${t('date_minute_short')}`;
        } else if (days < 1) {
            return `${t('date_in')} ${hours}${t('date_hour_short')}`;
        } else if (days <= 10) {
            return `${t('date_in')} ${days}${t('date_day_short')}`;
        } else {
            return targetDate.toLocaleDateString(t('date_locale'), { day: '2-digit', month: '2-digit', year: 'numeric' });
        }
    } else {
        // Past dates
        if (hours < 1) {
            return `${minutes}${t('date_minute_short')} ${t('date_ago')}`;
        } else if (hours < 12) {
            return `${hours}${t('date_hour_short')} ${minutes}${t('date_minute_short')} ${t('date_ago')}`;
        } else if (days < 1) {
            return `${hours}${t('date_hour_short')} ${t('date_ago')}`;
        } else if (days <= 10) {
            return `${days}${t('date_day_short')} ${t('date_ago')}`;
        } else {
            return targetDate.toLocaleDateString(t('date_locale'), { day: '2-digit', month: '2-digit', year: 'numeric' });
        }
    }
};