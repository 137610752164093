// Container.js

import React from 'react';
import './Container.css';

function Container({ children, isMarginlessOnSmallScreen = false, isHeader = false }) {
    let containerClass = "container";
    if (isMarginlessOnSmallScreen) {
        containerClass += " container--marginless-on-small";
    }
    if (isHeader) {
        containerClass += " container--header";
    }
    return (
        <div className={containerClass}>
            {children}
        </div>
    );
}

export default Container;