// views/DealPage.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../services/api';
import Container from '../components/Container';
import ReportButton from '../components/ReportButton';
import { shareDeal } from '../components/ShareDeal';
import { AuthContext } from '../contexts/AuthContext';
import { HomePageContext } from '../contexts/HomePageContext';
import { handleVote, handleShareDeal, handleExternalLinkClick } from '../utils/userActions';
import Carousel from 'react-bootstrap/Carousel';
import { formatDateFuture, formatDatePast } from '../utils/utils.js';
import { useTranslation } from 'react-i18next';
import DescriptionBox from '../components/DescriptionBox';
import { containsProfanity } from '../utils/profanityCheck';
import './DealPage.css';


import viewsIcon from '../assets/global-icons/views.svg';
import deadlineIcon from '../assets/global-icons/deadline.svg';
import locationIcon from '../assets/global-icons/location.svg';
import thumbUpIcon from '../assets/global-icons/thumb_up.svg';
import thumbDownIcon from '../assets/global-icons/thumb_down.svg';
import thumbUpFullIcon from '../assets/global-icons/thumb_up_full.svg';
import thumbDownFullIcon from '../assets/global-icons/thumb_down_full.svg';
import shareIcon from '../assets/global-icons/share.svg';
import externalLinkIcon from '../assets/global-icons/link.svg';
import shopIcon from '../assets/global-icons/shop.svg';
import startDateIcon from '../assets/global-icons/start_date_dealpage.svg';

const copyToClipboard = (text, alertPopup, t) => {
    navigator.clipboard.writeText(text).then(() => {
      alertPopup(t('deal_page_promo_code_copied'), 'success');
    }).catch(err => {
      alertPopup(t('deal_page_promo_code_copy_failed'), 'error');
    });
};

const formatPrice = (price) => {
    if (price === null || price === undefined) {
        return '';
    }
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

function DealPage({alertPopup}) {
    const { t } = useTranslation();
    const { isAuthenticated, toggleModal } = useContext(AuthContext);
    const { scrollPosition, setScrollPosition } = useContext(HomePageContext);
    const [deal, setDeal] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const voteUpButtonRef = useRef(null); // Reference for vote up button
    const voteDownButtonRef = useRef(null); // Reference for vote down button
    const [newMessage, setNewMessage] = useState(''); // Main comment box
    const [replyMessages, setReplyMessages] = useState({}); // Reply boxes
    const [replyParentId, setReplyParentId] = useState(null); // Track which comment is being replied to
    const [mainError, setMainError] = useState(''); // Error for main comment box
    const [replyErrors, setReplyErrors] = useState({}); // Errors for reply boxes
    const commentMaxLength = 2000; // Maximum allowed length
    
    useEffect(() => {
        fetchDeal(id);
        incrementDealViewCounter(id);
    }, [id]);

    const fetchDeal = async (dealId) => {
      try {
          const fetchedDeal = await apiService.getDealById(dealId, alertPopup);
          if (!fetchedDeal) {
              alertPopup(t('deal_page_fetch_failed'), 'error'); // Display error message
              setTimeout(() => navigate('/'), 3000); // Redirect after 3 seconds
          } else {
              setDeal(fetchedDeal);
          }
      } catch (error) {
          alertPopup(t('deal_page_fetch_error'), 'error'); // Display error message
          setTimeout(() => navigate('/'), 3000);
      }
  };

    const onVote = (dealId, value) => {
      handleVote(
          dealId,
          value,
          (updatedDeals) => setDeal(updatedDeals[0]), // Update the single deal after vote
          toggleModal,
          apiService,
          isAuthenticated,
          [{ ...deal }],  // Wrap deal in an array to simulate the structure in HomePage
          { current: [voteUpButtonRef] }, // Pass a fake ref array for consistency
          { current: [voteDownButtonRef] }, // Pass a fake ref array for consistency
          alertPopup 
      );
    };

    const onShare = () => {
        handleShareDeal(deal, alertPopup, t);
    };

    const onLinkClick = () => {
      handleExternalLinkClick(deal);
    };

    const handleReportSubmit = () => {
        setDeal((prevDeal) => ({
            ...prevDeal,
            user_report_type: 'reported', // Update the report status appropriately
        }));
    };

    const incrementDealViewCounter = async (dealId) => {
        try {
            await apiService.incrementDealViewCounter(dealId);
        } catch (error) {
            //console.error("Failed to increment view counter:", error);
        }
    };

    function formatVoteCount(votes) {
        if (Math.abs(votes) >= 10000) {
            return `${Math.round(votes / 1000)}k`;
        } else if (Math.abs(votes) >= 1000) {
            return `${(votes / 1000).toFixed(1)}k`;
        } else {
            return votes.toString();
        }
    }

    const toggleReplyBox = (parentId) => {
      setReplyParentId((prevId) => {
          const newReplyParentId = prevId === parentId ? null : parentId;
          if (!newReplyParentId) {
              setReplyMessages((prev) => {
                  const updatedMessages = { ...prev };
                  delete updatedMessages[parentId];
                  return updatedMessages;
              });
          }
          return newReplyParentId;
      });
    };

    //Function to track changes in main comment box
    const handleMainChange = (value) => {
      if (value.length > commentMaxLength) {
          setMainError(`Message cannot exceed ${commentMaxLength} characters.`);
      } else {
          setMainError('');
      }
      setNewMessage(value);
    };

    //Function to track changes in reply comment box
    const handleReplyChange = (parentId, value) => {
        setReplyMessages((prev) => ({
            ...prev,
            [parentId]: value,
        }));
    
        setReplyErrors((prev) => {
            const updatedErrors = { ...prev };
            if (value.length > commentMaxLength) {
                updatedErrors[parentId] = `Reply cannot exceed ${commentMaxLength}  characters.`;
            } else {
                delete updatedErrors[parentId];
            }
            return updatedErrors;
        });
    };
    
    const handleSendMessage = async (parentId = null) => {
        const message = parentId ? replyMessages[parentId] : newMessage;
    
        // Check if user is authenticated
        if (!isAuthenticated) {
            toggleModal(); // Show the connecting modal
            return;
        }
    
        // Check if message exists
        if (!message) {
            return;
        }
    
        // Strip HTML tags and trim the resulting string for checking empty content
        const strippedMessage = message.replace(/<\/?[^>]+(>|$)/g, '').trim();
    
        // Check for empty stripped message
        if (!strippedMessage) {
            return;
        }
        
        // Check for length
        if (message.length > commentMaxLength) {
            if (parentId) {
                setReplyErrors((prev) => ({
                    ...prev,
                    [parentId]: `Reply cannot exceed ${commentMaxLength} characters.`,
                }));
            } else {
                setMainError(`Message cannot exceed ${commentMaxLength} characters.`);
            }
            return; // Do not proceed if there's an error
        }
    
        // Check for profanity
        if (containsProfanity(message)) {
            const errorMessage = t('deal_page_error_profanity');
            if (parentId) {
                setReplyErrors((prev) => ({
                    ...prev,
                    [parentId]: errorMessage,
                }));
            } else {
                setMainError(errorMessage);
            }
            return; // Stop submission
        }
    
        // Generate a temporary ID and current date for optimistic UI
        const tempId = `temp-${Date.now()}`;
        const currentTime = new Date().toISOString();
    
        // Create a new comment
        const newComment = {
            _id: tempId,
            comment_description: message.trim(),
            comment_parent: parentId || null,
            deal_id: deal._id,
            comment_date: currentTime,
            comment_status: "Valid",
            alias: "You",
        };
    
        // Add the temporary comment and log the state
        setDeal((prevDeal) => {
            const updatedComments = [...prevDeal.comments, newComment];
            return {
                ...prevDeal,
                comments: updatedComments,
            };
        });
    
        try {
            const updatedComments = await apiService.addCommentOnDeal(
                {
                    comment_description: message.trim(),
                    comment_parent: parentId || null,
                    deal_id: deal._id,
                },
                alertPopup
            );
    
            if (updatedComments && !updatedComments.error) {
                setDeal((prevDeal) => ({
                    ...prevDeal,
                    comments: updatedComments,
                }));
    
                // Clear the input only if the server request is successful
                if (parentId) {
                    setReplyMessages((prev) => {
                        const updatedMessages = { ...prev };
                        delete updatedMessages[parentId];
                        return updatedMessages;
                    });
                } else {
                    setNewMessage(''); // Clear the main comment box
                }
            } else {
                throw new Error(updatedComments.details || 'Unknown server error');
            }
        } catch (error) {
            console.error('Error while sending the message:', error.message);
    
            // Remove only the temporary comment
            setDeal((prevDeal) => {
                const filteredComments = prevDeal.comments.filter((comment) => comment._id !== tempId);
                return {
                    ...prevDeal,
                    comments: filteredComments,
                };
            });
    
            alertPopup(t('deal_page_error_sending_comment'), 'error');
        }
    };

    if (!deal) {
        return <div>Loading...</div>;
    }

    const images = [deal.image1, deal.image2, deal.image3].filter(Boolean);
    const percentageReduction = deal.regular_price && deal.discounted_price ? 
        Math.round(((deal.regular_price - deal.discounted_price) / deal.regular_price) * 100) : null;

    return (
        <div className="deal-page">
            <Container isMarginlessOnSmallScreen={true}>
              <div className={deal.status === 'Expired' ? 'deal-page__expired-content' : ''}>
                {deal.status === 'Expired' && <div className="deal-page__expired-overlay"></div>}
      
                <div className="deal-page__deal-box">
                  <div className="deal-page__main-content">
                    <div className="deal-page__image-container">
                      {deal.status === 'Expired' && (
                        <div className="deal-page__expired-header">{t('deal_page_expired')}</div>
                      )}
                      {images.length > 0 ? (
                        <Carousel indicators={true}>
                          {images.map((image, index) => (
                            <Carousel.Item key={index}>
                              <img className="d-block w-100" src={image} alt={t('deal_page_image_alt', { index: index + 1 })} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      ) : (
                        <div className="deal-page__no-image-placeholder">{t('deal_page_no_image')}</div>
                      )}
                    </div>
      
                    <div className="deal-page__summary">
                      <div className="deal-page__view-name-box">
                        <div className="deal-page__view-box">
                          <div className="deal-page__view-item">
                            <img src={viewsIcon} alt={t('deal_page_views')} className="deal-page__summary-icon" />
                            <div className="deal-page__view-item-text">{deal.view_counter} {t('deal_page_views')}</div>
                          </div>
                          {deal.start_date && (
                            <div className="deal-page__view-item">
                              <img src={startDateIcon} alt={t('deal_page_deadline')} className="deal-page__summary-icon" />
                                <div className="deal-page__view-item-text">
                                  {formatDatePast(deal.start_date, t)} {/* Use formatDate for consistent date format */}
                                </div>
                              </div>
                          )}
                          {deal.end_date && (
                            <div className="deal-page__view-item">
                              <img src={deadlineIcon} alt={t('deal_page_deadline')} className="deal-page__summary-icon" />
                                <div className="deal-page__view-item-text">
                                  {formatDateFuture(deal.end_date, t)} {/* Use formatDate for consistent date format */}
                                </div>
                              </div>
                          )}
                          <div className="deal-page__view-item">
                            <img src={locationIcon} alt={t('deal_page_location')} className="deal-page__summary-icon" />
                            <div className="deal-page__view-item-text">{deal.location === 'National' ? t('location.national') : deal.location}</div>
                          </div>
                          {deal.shop && (
                            <div className="deal-page__view-item">
                              <img src={shopIcon} alt={t('deal_page_shop')} className="deal-page__summary-icon" />
                              <div className="deal-page__view-item-text">{deal.shop}</div>
                            </div>
                          )}
                        </div>
                        <div className="deal-page__name-box">
                          <div className="deal-page__date-registering-box">
                            {t('deal_page_added_sentence')}{formatDatePast(deal.date_registering, t)}
                          </div>
                          <div className="deal-page__title">{deal.title}</div>
                          {deal.promo_code && (
                            <div className="deal-page__promo-code" onClick={() => copyToClipboard(deal.promo_code, alertPopup, t)}>
                              {t('deal_page_promo_code')}: <span className="deal-page__promo-code-value">{deal.promo_code}</span>
                            </div>
                          )}
                        </div>
                      </div>
      
                      <div className="deal-page__price-box">
                        {deal.regular_price !== null && deal.regular_price !== undefined && (
                          <div className="deal-page__regular-price">
                            <span className="deal-page__currency-symbol-regular-price">đ</span>
                            {formatPrice(deal.regular_price)}
                          </div>
                        )}
                        {(deal.discounted_price !== null && deal.discounted_price !== undefined) || percentageReduction ? (
                          <div className="deal-page__current-price-container">
                            {deal.discounted_price !== null && deal.discounted_price !== undefined && (
                              <div className="deal-page__current-price">
                                <span className="deal-page__currency-symbol-current-price">đ</span>
                                {formatPrice(deal.discounted_price)}
                              </div>
                            )}
                            {percentageReduction !== null && percentageReduction >= 1 && (
                              <div className="deal-page__reduction">
                                -{percentageReduction}% {t('deal_page_discount')}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
      
                      <div className="deal-page__interaction-box">
                        <div className="deal-page__vote-container">
                          <div className="deal-page__vote-section">
                            <button
                              ref={voteDownButtonRef}
                              className={`deal-page__vote-button thumb-down ${deal.user_vote !== 0 ? 'disabled' : ''}`}
                              onClick={() => onVote(deal._id, -1)}
                              aria-label={t('deal_page_vote_down')}
                            >
                              <img
                                src={deal.user_vote === -1 ? thumbDownFullIcon : thumbDownIcon}
                                alt={t('deal_page_vote_down')}
                                className="deal-page__vote-icon"
                              />
                            </button>
                          </div>
                          <div className="deal-page__vote-section">
                            <span className={deal.vote >= 0 ? 'deal-page__vote-positive-count' : ''}>
                              {formatVoteCount(deal.vote)}
                            </span>
                          </div>
                          <div className="deal-page__vote-section">
                            <button
                              ref={voteUpButtonRef}
                              className={`deal-page__vote-button thumb-up ${deal.user_vote !== 0 ? 'disabled' : ''}`}
                              onClick={() => onVote(deal._id, 1)}
                              aria-label={t('deal_page_vote_up')}
                            >
                              <img
                                src={deal.user_vote === 1 ? thumbUpFullIcon : thumbUpIcon}
                                alt={t('deal_page_vote_up')}
                                className="deal-page__vote-icon"
                              />
                            </button>
                          </div>
                        </div>
                        {deal.link ? (
                          <button onClick={onLinkClick} className="deal-page__deal-link-button">
                            <img src={externalLinkIcon} alt={t('deal_page_external_link')} />
                            {t('deal_page_see_deal')}
                          </button>
                        ) : (
                          <div className="deal-page__deal-link-placeholder"></div>
                        )}
                        <div className="deal-page__share-box" onClick={onShare}>
                          <img src={shareIcon} alt={t('deal_page_share')} className="deal-page__share-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="deal-page__description deal-page__description--first">
                    <h2>
                      {t('deal_page_about_deal')}
                    </h2>
                      <div dangerouslySetInnerHTML={{ __html: deal.description }}></div>

                    <div className="deal-page__description-buttons">
                      <ReportButton
                        dealId={deal._id}
                        userReportType={deal.user_report_type}
                        alertPopup={alertPopup}
                        onReportSubmit={handleReportSubmit}
                      />
                    </div>
                  </div>
        
                  {/* Messages Box */}
                  <div className="deal-page__description">
                    <h2>{t('deal_page_comments')}</h2>
                    <DescriptionBox value={newMessage} onChange={handleMainChange} />
                    
                    <button
                        className="deal-page__send-button"
                        onClick={() => handleSendMessage(null)} // Pass null for main comment
                    >
                        {t('deal_page_send_comment')}
                    </button>
                    {mainError && <div className="error-text">{mainError}</div>} {/* Display error */}

                    <div className="deal-page__comments">
                      {deal.comments && deal.comments.length > 0 ? (
                        // Sort comments by date, latest first
                        deal.comments
                          .filter((comment) => !comment.comment_parent) // Only top-level comments
                          .sort((a, b) => new Date(b.comment_date) - new Date(a.comment_date))
                          .map((comment) => (
                            <div key={comment._id} className="deal-page__comment">
                              <span className="deal-page__comment-alias">{comment.alias}</span> {/* Display the user's alias */}
                              <span className="deal-page__comment-date">
                                {formatDatePast(comment.comment_date, t)} {/* Format comment date */}
                              </span>
                              <div dangerouslySetInnerHTML={{ __html: comment.comment_description }}></div>

                              {/* Replies Section */}
                              <div className="deal-page__replies">
                                {deal.comments
                                  .filter((reply) => reply.comment_parent === comment._id) // Replies for this comment
                                  .sort((a, b) => new Date(b.comment_date) - new Date(a.comment_date)) // Sort replies by date
                                  .map((reply) => (
                                    <div key={reply._id} className="deal-page__reply">
                                      <span className="deal-page__reply-alias">{reply.alias}</span> {/* Display reply user's alias */}
                                      <span className="deal-page__reply-date">
                                        {formatDatePast(reply.comment_date, t)}
                                      </span>
                                      <div dangerouslySetInnerHTML={{ __html: reply.comment_description }}></div>
                                    </div>
                                  ))}
                                  
                                {/* Reply Form */}
                                {replyParentId === comment._id && (
                                  <div className="deal-page__reply-box">
                                      <DescriptionBox
                                          value={replyMessages[comment._id] || ''} // Use replyMessages state
                                          onChange={(value) => handleReplyChange(comment._id, value)} // Update reply state
                                      />
                                      <button
                                          className="deal-page__send-button"
                                          onClick={() => handleSendMessage(comment._id)} // Pass parentId
                                      >
                                          {t('deal_page_send_reply')}
                                      </button>
                                      {replyErrors[comment._id] && <div className="error-text">{replyErrors[comment._id]}</div>} {/* Display error */}
                                      <button
                                          className="deal-page__reply-button"
                                          onClick={() => toggleReplyBox(null)} // Close reply box
                                      >
                                          {t('deal_page_hide')}
                                      </button>
                                  </div>
                              )}
                              </div>

                              {/* Reply Button for Parent Comments */}
                              {replyParentId !== comment._id && (
                                <button
                                    className="deal-page__reply-button"
                                    onClick={() => toggleReplyBox(comment._id)}
                                >
                                    {t('deal_page_reply')}
                                </button>
                              )}
                            </div>
                          ))
                      ) : (
                        <p className="no-comments-message">{t('deal_page_no_comments')}</p>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </Container>
          </div>
        );
    }
    
    export default DealPage;