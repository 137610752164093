import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next'; // Import i18next translation hook
import { useLocation } from 'react-router-dom';
import './CategoryHeader.css';
import ArrowLeft from '../assets/VerticalMenu_ArrowLeft.svg';
import ArrowRight from '../assets/VerticalMenu_ArrowRight.svg';

// Import the static CATEGORIES array
import { CATEGORIES } from '../constants/CategoriesHeaderList';

const CategoryHeader = () => {
    const { t } = useTranslation(); // Initialize the translation hook
    const categoriesContainerRef = useRef(null);
    const transitionDuration = 300; // Match the duration to your CSS variable (in milliseconds)
    const location = useLocation(); // Get the current location

    // Function to parse category from URL query parameters
    const getCurrentCategoryFromQuery = () => {
        const query = new URLSearchParams(location.search);
        return query.get('name') || '';
    };

    // Get the current category (this is the English version as stored in the URL)
    const currentCategory = getCurrentCategoryFromQuery();

    // Handle category click (always use the translationKey which is the English version)
    const handleCategoryClick = (categoryKey) => {
        // Get the English category name using the translation key
        const englishCategoryName = t(categoryKey, { lng: 'en' });
    
        // Encode the English category name for the URL
        const encodedCategoryName = encodeURIComponent(englishCategoryName);
    
        // Delay the navigation by 10 seconds (10,000 milliseconds)
        window.location.href = `/category?name=${encodedCategoryName}`;
    };

    const scrollCategories = (direction) => {
        const container = categoriesContainerRef.current;
        if (container) {
            container.style.scrollBehavior = 'smooth';
            const transitionDistance = Math.max(container.offsetWidth - 50, 0);

            if (direction === 'left') {
                container.scrollLeft -= transitionDistance;
            } else {
                container.scrollLeft += transitionDistance;
            }

            setTimeout(() => {
                container.style.scrollBehavior = 'auto';
            }, transitionDuration);
        }
    };

    return (
        <div className="category-header">
            <div className="category-header__container">
                <div className="category-header__arrow-box">
                    <button className="category-header__arrow" onClick={() => scrollCategories('left')}>
                        <img src={ArrowLeft} alt="Left" />
                    </button>
                </div>
                <div className="category-header__categories-container" ref={categoriesContainerRef}>
                    {CATEGORIES.map(category => (
                        <button 
                            key={category.id}
                            // Compare using the translationKey (English) for consistency with the URL
                            className={`category-header__category-button ${category.translationKey === currentCategory ? 'category-header__category-button--selected' : ''}`}
                            onClick={() => handleCategoryClick(category.translationKey)} // Use translationKey for URL navigation
                        >
                            <img src={category.icon} alt={t(category.translationKey)} className="category-header__category-icon"/>
                            <span className="category-header__category-text">{t(category.translationKey)}</span> {/* Display translated category name */}
                        </button>
                    ))}
                </div>
                <div className="category-header__arrow-box">
                    <button className="category-header__arrow" onClick={() => scrollCategories('right')}>
                        <img src={ArrowRight} alt="Right" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CategoryHeader;
