// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Detects browser language
import en from './locales/en.json'; // Import your translation files
import vn from './locales/vn.json';

i18n
  .use(LanguageDetector) // Automatically detects the user's language preference
  .use(initReactI18next) // Connects i18n with React
  .init({
    resources: {
      en: {
        translation: en,
      },
      vn: {
        translation: vn,
      },
    },
    fallbackLng: 'vn', // Fallback language if user's language isn't available
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    detection: {
      order: ['querystring', 'localStorage', 'cookie', 'navigator'],
      caches: ['localStorage'], // Caches user language in localStorage
    },
  });

export default i18n;