import React, { useState, useContext, useRef } from 'react';
import apiService from '../services/api';
import './ReportButton.css';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

import closeIcon from '../assets/global-icons/close.svg';

function ReportButton({ dealId, userReportType, alertPopup, onReportSubmit }) {
    const { t } = useTranslation();
    const { isAuthenticated, toggleModal } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');
    const [reportType, setReportType] = useState('');
    const [step, setStep] = useState(1); // Step 1 for selecting report type, Step 3 for entering comment
    const [errorMessageMaxLenght, setErrorMessageMaxLenght] = useState('');
    const [errorMessageMinLenght, setErrorMessageMinLenght] = useState('');
    const MAX_COMMENT_LENGTH = 1000; // Define maximum comment length
    const buttonRef = useRef(null); // Reference to the button

    const handleOpenModal = async () => {
        if (!isAuthenticated) {
            toggleModal();
            return;
        }

        if (userReportType !== null) {
          alertPopup(t('report_button_already_reported_message'), 'error');
          // Add shake animation
          buttonRef.current.classList.add('shake');
          setTimeout(() => {
              buttonRef.current.classList.remove('shake');
          }, 500);
          return;
        }

        try {
          setShowModal(true);
          setStep(1);
          setComment('');
          setReportType('');
        } catch (error) {
          alertPopup(t('report_button_error_message', { error: error.message }), 'error');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setComment('');
        setReportType('');
        setErrorMessageMaxLenght(''); // Clear any previous error message
        setErrorMessageMinLenght(''); // Clear any previous error message
    };

    const handleSelectReportType = (type) => {
        setReportType(type);
        setStep(3); // Move to comment step
    };

    const handleCommentChange = (e) => {
        const newComment = e.target.value;
        if (newComment.length > MAX_COMMENT_LENGTH) {
            setErrorMessageMaxLenght(t('report_button_comment_too_long', { maxLength: MAX_COMMENT_LENGTH }));
        } else if (newComment.length < 1) {
          setErrorMessageMinLenght(t('report_button_comment_required'));
        } else {
          setErrorMessageMinLenght(``);
          setErrorMessageMaxLenght(``);
        }
        setComment(newComment);
    };

    const handleSubmit = async () => {
      if (reportType !== 'Expired' && comment.trim() === '') {
        setErrorMessageMinLenght(t('report_button_error_min_length')); // Use translation for minimum length error
        return;
      }

      if (comment.length > MAX_COMMENT_LENGTH) {
          return;
      }

      try {
          await apiService.submitReport(dealId, reportType, comment, alertPopup);
          handleCloseModal();
          alertPopup(t('report_button_success_message'), 'success'); // Use translation for success message
          onReportSubmit(); // Call the onReportSubmit method passed from DealPage
      } catch (error) {
          alertPopup(t('report_button_error_message'), 'error'); // Use translation for error message
      }
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseModal();
        }
    };

    const handleExpiredReport = async () => {
        if (!isAuthenticated) {
          toggleModal();
          return;
        }

        try {
          await apiService.submitReport(dealId, "Expired", "", alertPopup);
          alertPopup(t('report_button_success_message'), 'success');
          onReportSubmit(); // Call the onReportSubmit method passed from DealPage
        } catch (error) {
          alertPopup(t('report_button_error_message'), 'error');
        }
    };

    return (
    <div className="report-button__container">
      <button
        ref={buttonRef} // Attach the ref to the button
        className={`report-button__trigger ${userReportType !== null ? 'reported' : ''}`}
        onClick={handleOpenModal}
      >
        {userReportType !== null ? t('report_button_already_reported') : t('report_button_report')}
      </button>
      {userReportType === null && (
        <button className="report-button__trigger" onClick={handleExpiredReport}>
          {t('report_button_deal_expired')}
        </button>
      )}
      {showModal && (
        <div className="report-button__modal" onClick={handleBackgroundClick}>
          <div className={`report-button__modal-content ${step === 3 ? 'report-button__modal-content--expanded' : ''}`}>
            <div className="report-button__modal-header">
              <h2>{t('report_button_report_deal_title')}</h2>
              <img src={closeIcon} alt={t('report_button_close')} className="report-button__close" onClick={handleCloseModal} />
            </div>
            {step === 1 && (
              <div className="report-button__options">
                <button className="report-button__option" onClick={() => handleSelectReportType('Spam')}>
                  {t('report_button_option_spam')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('This deal already exists')}>
                  {t('report_button_option_already_exists')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('Missing information')}>
                  {t('report_button_option_missing_info')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('Counterfeit product')}>
                  {t('report_button_option_counterfeit')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('Inappropriate/Insulting/Sexual')}>
                  {t('report_button_option_inappropriate')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('Limited Offer/Not available globally')}>
                  {t('report_button_option_limited_offer')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('Not the best available deal')}>
                  {t('report_button_option_not_best')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('Wrong category')}>
                  {t('report_button_option_wrong_category')}
                </button>
                <button className="report-button__option" onClick={() => handleSelectReportType('Other')}>
                  {t('report_button_option_other')}
                </button>
              </div>
            )}
            {step === 3 && (
              <>
                {errorMessageMaxLenght && <p className="report-button__error-message">{errorMessageMaxLenght}</p>}
                {errorMessageMinLenght && <p className="report-button__error-message">{errorMessageMinLenght}</p>}
                <textarea
                  className="report-button__textarea"
                  placeholder={t('report_button_comment_placeholder')}
                  value={comment}
                  onChange={handleCommentChange}
                ></textarea>
                <button onClick={handleSubmit} className="report-button__trigger">
                  {t('report_button_send')}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
    );
}

export default ReportButton;