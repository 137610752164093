// components/LoginModal.js

import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import './LoginModal.css';
import GoogleLogo from '../assets/google-logo.svg';
import ZaloLogo from '../assets/zalo-logo.svg'; // Add Zalo logo here
import { API_URL } from '../services/api';
import closeIcon from '../assets/global-icons/close.svg';
import { useTranslation } from 'react-i18next';

function LoginModal({ onClose }) {
    const { t } = useTranslation();
    const { isAuthenticated } = useContext(AuthContext);
    const modalRef = useRef(null);

    // Handle authentication success
    useEffect(() => {
        if (isAuthenticated) {
            onClose(); // Close modal
        }
    }, [isAuthenticated, onClose]);

    // Handle click outside of modal to close
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose(); // Close the modal if click is outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    // Function to create the authentication URL with a return path
    const createAuthUrl = (authType) => {
        const currentPath = window.location.pathname || '/'; // Fallback to root if path is empty
        return `${API_URL}auth/${authType}?returnPath=${encodeURIComponent(currentPath)}`;
    };

    return (
        <div className="login-modal">
            <div className="login-modal__content" ref={modalRef}>
                <div className="login-modal__header">
                    <h2 className="login-modal__title">{t('login_modal_title')}</h2> {/* Translated Title */}
                    <img src={closeIcon} alt={t('login_modal_close_alt')} className="login-modal__close" onClick={onClose} /> {/* Translated Close Alt */}
                </div>
                <div className="login-modal__auth-buttons">
                    <button onClick={() => window.location.href = createAuthUrl('google')} className="login-modal__auth-button login-modal__auth-button--google">
                        <img src={GoogleLogo} alt={t('login_modal_google_alt')} className="login-modal__auth-icon" /> {/* Translated Google Alt */}
                        {t('login_modal_google')} {/* Translated Google Button Text */}
                    </button>
                    <button onClick={() => window.location.href = createAuthUrl('zalo')} className="login-modal__auth-button login-modal__auth-button--zalo">
                        <img src={ZaloLogo} alt={t('login_modal_zalo_alt')} className="login-modal__auth-icon" /> {/* Translated Zalo Alt */}
                        {t('login_modal_zalo')} {/* Translated Zalo Button Text */}
                    </button>
                </div>
                <div className="login-modal__terms">
                    {t('login_modal_terms_prefix')} <a href="/terms-and-conditions" target="_blank">{t('login_modal_terms_link')}</a>.
                </div>
            </div>
        </div>
    );
}

export default LoginModal;