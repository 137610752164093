import React from 'react';
import Container from '../components/Container'; // Adjust the import path as needed
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <Container>
            <div className="terms-and-conditions">
                <p>
                    ====================<br></br>
                    <strong>ENGLISH VERSION BELOW</strong><br></br>
                    ====================
                </p>
                <br></br>

                <h1>ĐIỀU KHOẢN SỬ DỤNG</h1>
                <div className="sub-heading">Điều Khoản Sử Dụng của Dealio</div>

                <h2>1. Định Nghĩa và Mục Đích</h2>
                <p>Dealio là một nền tảng trực tuyến cung cấp không gian cộng đồng để chia sẻ các ưu đãi khuyến mãi khác nhau. Thông qua trang web của mình, Dealio cung cấp cho người dùng một nền tảng tạm thời và có thể thu hồi nơi mà các thành viên có thể:</p>
                <ul>
                    <li>Tạo và quản lý hồ sơ cá nhân, xem các ưu đãi của họ trên trang web, và xem các ưu đãi từ các thành viên khác.</li>
                    <li>Đăng các ưu đãi.</li>
                    <li>Bình chọn cho hoặc phản đối các ưu đãi.</li>
                    <li>Tham gia vào các cuộc thảo luận với các thành viên khác bằng cách đăng bình luận.</li>
                    <li>Nhận tất cả các thông báo từ Dealio dành cho thành viên, như các tin nhắn hành chính, mà thành viên đồng ý nhận.</li>
                </ul>
                <p>Các chức năng này được cung cấp như hiện có và không giới hạn ở danh sách trên. Dealio có quyền thay đổi hoặc xóa bất kỳ chức năng nào trong số này bất cứ lúc nào mà không cần thông báo trước hoặc chịu trách nhiệm. Việc truy cập vào trang web Dealio và sử dụng nội dung của nó bởi người dùng phụ thuộc vào các Điều Khoản Sử Dụng này.</p>

                <p className="important-notice"><strong>THÔNG BÁO QUAN TRỌNG CHO NGƯỜI DÙNG</strong></p>
                <p className="uppercase">SỬ DỤNG TRANG WEB DEALIO HOẶC BẤT KỲ TÍNH NĂNG NÀO CỦA TRANG WEB DEALIO ĐỒNG NGHĨA VỚI VIỆC NGƯỜI DÙNG ĐÃ CHẤP NHẬN ĐẦY ĐỦ VÀ TOÀN BỘ CÁC ĐIỀU KHOẢN SỬ DỤNG NÀY. BẰNG CÁCH TẠO HỒ SƠ, NGƯỜI DÙNG CÔNG NHẬN RẰNG HỌ ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN SỬ DỤNG NÀY BẰNG CÁCH NHẤP VÀO NÚT "ĐĂNG KÝ". CÁC ĐIỀU KHOẢN SỬ DỤNG NÀY ĐƯỢC CẬP NHẬT THƯỜNG XUYÊN, VÀ NGƯỜI DÙNG ĐƯỢC MỜI XEM LẠI CHÚNG TRONG MỖI LẦN TRUY CẬP.</p>

                <h3>1.1. Định Nghĩa</h3>
                <p>Các định nghĩa dưới đây ở dạng số ít cũng áp dụng cho dạng số nhiều, và ngược lại, tùy thuộc vào ngữ cảnh:</p>
                <ul>
                    <li><strong>"Điều Khoản"</strong> đề cập đến các điều khoản sử dụng này áp dụng cho tất cả người dùng của trang web.</li>
                    <li><strong>"Nội Dung"</strong> đề cập đến bất kỳ yếu tố hình ảnh hoặc văn bản nào được thành viên đăng tải trên trang web và liên quan đến hồ sơ của họ.</li>
                    <li><strong>"Dealio"</strong> đề cập đến công ty vận hành nền tảng www.dealio.asia, có thể liên lạc qua email dealio.vn@gmail.com.</li>
                    <li><strong>"Người Bán"</strong> đề cập đến các nhà bán lẻ trực tuyến hoặc vật lý chuyên nghiệp có các ưu đãi thương mại có thể được thành viên đăng tải dưới dạng ưu đãi.</li>
                    <li><strong>"Người Dùng"</strong> đề cập đến bất kỳ người dùng nào đã đăng ký trên trang web theo mục 4 của các Điều Khoản này.</li>
                    <li><strong>"Ưu Đãi" hoặc "Deal"</strong> đề cập đến một ưu đãi khuyến mãi bao gồm một liên kết dẫn đến cửa hàng trực tuyến hoặc vật lý của một công ty thương mại bên thứ ba.</li>
                    <li><strong>"Hồ Sơ"</strong> đề cập đến trang cá nhân của thành viên trên trang web, chỉ có thể truy cập sau khi thành viên đã đăng nhập bằng email và mật khẩu hoặc qua mạng xã hội. Từ hồ sơ của mình, thành viên có thể bổ sung thông tin họ muốn chia sẻ với các thành viên khác, truy cập vào các cài đặt và quản lý không gian của họ.</li>
                    <li><strong>"Dịch Vụ"</strong> đề cập đến các tính năng khác nhau do trang web cung cấp cho người dùng, chẳng hạn như bỏ phiếu cho các ưu đãi hoặc tham gia thảo luận.</li>
                    <li><strong>"Trang Web"</strong> đề cập đến trang web Dealio, với địa chỉ chính www.dealio.asia. Trang web bao gồm tất cả các thành phần CNTT (bao gồm phần mềm và phát triển, trang web, mã nguồn và mã đối tượng, tên miền, công việc thiết kế chuẩn bị, thông số kỹ thuật, nghiên cứu sơ bộ, tài liệu hướng dẫn người dùng, cơ sở dữ liệu), thiết kế đồ họa (bao gồm dự án, mô hình, nguyên mẫu, kế hoạch), nội dung (bao gồm yếu tố văn bản, đồ họa, âm thanh, hình ảnh, đa phương tiện) và các chức năng, cũng như tất cả các quyền liên quan.</li>
                </ul>

                <h3>1.2. Mục Đích</h3>
                <p>Các Điều Khoản này điều chỉnh các điều kiện và cách thức sử dụng dịch vụ của trang web bởi người dùng và thành viên, cũng như xác định quyền và nghĩa vụ của người dùng và thành viên kết nối qua trang web.</p>

                <h2>2. Truy Cập và Hồ Sơ</h2>
                <h3>2.1. Truy Cập Vào Trang Web và Hồ Sơ</h3>
                <p>Trang web có thể truy cập thông qua URL: www.dealio.asia</p>
                <p>Truy cập vào hồ sơ mở cho tất cả người dùng internet đã đăng ký theo mục 4: Đăng Ký Thành Viên và Tạo Hồ Sơ. Sau khi đăng ký, các thành viên có thể đăng nhập vào hồ sơ của họ qua phương thức nhận diện mạng xã hội.</p>
                <p>Các dịch vụ của trang web có thể phát triển với các bản cập nhật để đáp ứng các yêu cầu kỹ thuật, thương mại hoặc pháp lý mới. Do đó, Dealio không chịu trách nhiệm cho sự phát triển như vậy, bao gồm việc loại bỏ bất kỳ dịch vụ nào hiện có vào thời điểm đăng ký trên trang web.</p>

                <h3>2.2. Đăng Ký Thành Viên và Tạo Hồ Sơ</h3>
                <p>Đăng ký trên hồ sơ được thực hiện thông qua trang web. Nó miễn phí, ngoại trừ phí kết nối, người dùng phải tự chịu trách nhiệm. Nó bao gồm việc chấp nhận đầy đủ, hoàn toàn và không giới hạn các Điều Khoản này.</p>
                <p>Bất kỳ người nào từ mười ba (13) tuổi trở lên đều có thể trở thành thành viên. Dealio không can thiệp vào việc tạo hồ sơ và không xác minh tính chính xác, độ tin cậy hoặc kịp thời của thông tin do thành viên cung cấp. Do đó, Dealio không chịu trách nhiệm cho thông tin chứa trong hồ sơ.</p>
                <p>Các thành viên được mong đợi tuân thủ các tiêu chuẩn cao về lịch sự, đạo đức và tôn trọng lẫn nhau. Các thành viên đồng ý không sử dụng biệt danh hoặc hình đại diện có thể gây khó chịu cho người khác, trái với trật tự công cộng hoặc đạo đức, hoặc vi phạm quyền của bên thứ ba, hoặc mang tính chất quảng cáo. Dealio có quyền kiểm duyệt các biệt danh và hình đại diện được sử dụng bởi các thành viên và có thể từ chối một đăng ký. Một người không thể có nhiều hồ sơ trên trang web.</p>

                <h3>2.3. Truy Cập Vào Trang Web</h3>
                <p>Để truy cập vào trang web, người dùng phải:</p>
                <ul>
                    <li>Chấp nhận cookie và kích hoạt JavaScript.</li>
                    <li>Đủ tuổi hoặc có sự đồng ý trước từ người đại diện hợp pháp của họ.</li>
                </ul>
                <p>Truy cập vào trang web có thể được thực hiện từ máy tính, điện thoại thông minh hoặc máy tính bảng kết nối với www.dealio.asia bằng bất kỳ phương tiện liên lạc nào. Dealio không cung cấp cho người dùng bất kỳ thiết bị nào, cài đặt điện thoại, thiết bị đầu cuối, vật tư tiêu hao hoặc các vật liệu khác. Bất kể chế độ kết nối được chọn, phí kết nối là trách nhiệm của người dùng, cũng như bất kỳ khoản tiền, phí, đăng ký, thuế hoặc các khoản phí khác mà nhà cung cấp dịch vụ truy cập mạng viễn thông yêu cầu để kết nối người dùng với trang web.</p>
                <p>Dealio nỗ lực hết mình để đảm bảo trang web luôn sẵn sàng. Tuy nhiên, người dùng được thông báo rằng trang web không phải chịu bất kỳ nghĩa vụ nào về sự sẵn sàng, khả năng truy cập hoặc hiệu suất. Do đó, người dùng không thể yêu cầu bất kỳ lỗi nào ngăn cản việc sử dụng trang web hoặc làm giảm trải nghiệm người dùng.</p>
                <p>Trang web hoạt động 24/7, nhưng Dealio có quyền thay đổi hoặc tạm thời hoặc vĩnh viễn ngừng truy cập vào trang web để bảo trì, cập nhật, cải tiến hoặc phát triển nội dung và/hoặc trình bày của nó mà không cần thông báo hoặc bồi thường. Dealio sẽ thông báo trước cho các thành viên về các hoạt động này bất cứ khi nào có thể.</p>
                <p>Dealio có quyền tạm ngưng mà không cần thông báo các tài khoản nhiều lần của cùng một thành viên hoặc tài khoản của thành viên không tuân thủ các Điều Khoản này, bao gồm nhưng không giới hạn ở: đăng nội dung giả mạo hoặc gây hiểu lầm, tham gia vào hành vi lạm dụng hoặc không phù hợp, sử dụng trang web cho các hoạt động bất hợp pháp, mạo danh thành viên khác, tự quảng cáo, tránh các biện pháp trừng phạt, gửi thư rác, gây hại cho trang web hoặc người dùng của nó, vi phạm quyền sở hữu trí tuệ và đăng nội dung phỉ báng, xúc phạm, phân biệt chủng tộc, bài ngoại hoặc khiêu dâm.</p>

                <h2>3. Quyền Sở Hữu Trí Tuệ</h2>
                <h3>3.1. Quyền Sở Hữu Trí Tuệ</h3>
                <h4>3.1.1. Quyền Sở Hữu Trí Tuệ của Người Dùng</h4>
                <p>Người dùng tuyên bố và đảm bảo rằng họ là chủ sở hữu duy nhất của tất cả các quyền sở hữu trí tuệ và/hoặc quyền hình ảnh trên thông tin (văn bản, hình ảnh, bản vẽ, ảnh, bình luận, thương hiệu, tên xã hội, v.v.) và/hoặc bình luận được xuất bản hoặc phân phối trên trang web. Nếu không, họ phải có tất cả các quyền, ủy quyền và chuyển nhượng cần thiết để xuất bản và hiển thị thông tin đó và cấp quyền cho Dealio như mô tả dưới đây. Các thành viên đồng ý kiểm tra các điều khoản sử dụng và sao chép nội dung của trang web thương mại từ đó xuất phát ưu đãi trước khi đăng bất kỳ ưu đãi nào.</p>
                <p>Họ cũng đảm bảo rằng thông tin và/hoặc bình luận và/hoặc ưu đãi được xuất bản hoặc phân phối không tạo thành, toàn bộ hoặc một phần, các hành vi làm giả, cạnh tranh không lành mạnh, ký sinh thương mại, hoặc vi phạm quyền hình ảnh của người hoặc tài sản. Người dùng giữ tất cả các quyền sở hữu trí tuệ có thể có trên thông tin (văn bản, hình ảnh, bản vẽ, ảnh, bình luận, thương hiệu, tên xã hội, v.v.) mà họ phân phối và/hoặc xuất bản trên hồ sơ của họ.</p>
                <p>Người dùng cấp cho Dealio giấy phép sử dụng nội dung họ xuất bản trên trang web, ngoại trừ tin nhắn riêng tư, trong toàn bộ thời gian bảo vệ quyền tác giả ở Việt Nam, trên toàn thế giới, miễn phí, không thể hủy bỏ, không độc quyền và có thể chuyển nhượng. Giấy phép sử dụng này bao gồm quyền cho Dealio sử dụng, sao chép, đại diện, thích nghi, dịch, hoặc cấp phép lại nội dung do các thành viên xuất bản, ngoại trừ tin nhắn riêng tư, toàn bộ hoặc một phần, trong mọi định dạng, bằng bất kỳ phương tiện nào, trên trang web hoặc trên tất cả các phương tiện quảng cáo và khuyến mãi liên quan trực tiếp đến trang web, không giới hạn số lượng bản sao của các phương tiện có liên quan, và các phương thức khai thác quảng cáo và khuyến mãi trực tiếp liên quan đến trang web (áp phích, biển quảng cáo, đặc biệt là tất cả các phương tiện hiển thị, bất kỳ vật phẩm quảng cáo nào không nhằm mục đích bán trực tiếp), cũng như cho các phương tiện và phương tiện sau: báo chí (báo, tạp chí), ấn bản giấy (danh mục, tập sách, tờ rơi, sách, thẻ, tờ rơi…), ấn bản đa phương tiện và điện tử (internet, intranet, …), tài liệu thương mại (tài liệu tổ chức, v.v…).</p>
                <p>Nếu Dealio nghi ngờ rằng thông tin và/hoặc bình luận và/hoặc ưu đãi từ các thành viên vi phạm quyền hình ảnh và/hoặc quyền sở hữu trí tuệ của bên thứ ba, họ có quyền:</p>
                <ul>
                    <li>Ngay lập tức xóa, không cần thông báo hoặc bồi thường, bất kỳ nội dung, thông tin và/hoặc bình luận gây tranh cãi nào;</li>
                    <li>Tạm ngưng hoặc xóa hồ sơ của thành viên theo điều kiện của mục 6 dưới đây;</li>
                    <li>Hạn chế hoặc chặn truy cập vào toàn bộ hoặc một phần các dịch vụ.</li>
                </ul>

                <h4>3.1.2. Quyền Sở Hữu Trí Tuệ của Dealio</h4>
                <p>Trang web và các yếu tố của nó được bảo vệ bởi các quyền sở hữu trí tuệ thuộc về Dealio. Người dùng bị cấm thực hiện bất kỳ hành động nào trực tiếp hoặc gián tiếp vi phạm quyền sở hữu trí tuệ của Dealio. Do đó, người dùng bị cấm xuất bản, sao chép, phân phối, đại diện, thích nghi, tháo rời, giải mã, ở Việt Nam hoặc ở nước ngoài, toàn bộ hoặc một phần nội dung của trang web; sử dụng công nghệ hoặc bí quyết của Dealio cho các mục đích thương mại; sử dụng thương hiệu và/hoặc hình ảnh của Dealio để tạo hoặc cung cấp dịch vụ tương tự hoặc cạnh tranh với trang web; hoặc đăng ký thương hiệu giống hoặc tương tự.</p>
                <p>Không có chuyển nhượng quyền sở hữu trí tuệ của Dealio được cấp cho người dùng. Người dùng chỉ được cấp quyền truy cập và sử dụng các dịch vụ và chức năng của trang web không độc quyền, miễn phí, có thể thu hồi và không thể chuyển nhượng trong thời gian sử dụng dịch vụ của trang web, như được quy định rõ ràng trong các Điều Khoản này. Mọi việc sử dụng dữ liệu hoặc thông tin từ trang web Dealio phải đề cập đến Dealio như nguồn thông tin và phải được sự cho phép trước bằng văn bản của quản trị viên Dealio.</p>

                <h2>4. Nghĩa Vụ của Người Dùng, Điều Kiện Đăng Ưu Đãi và Bình Chọn</h2>

                <h3>4.1. Nghĩa Vụ của Người Dùng</h3>
                <p>Người dùng đồng ý tuân thủ tất cả các Điều Khoản này và các luật và quy định hiện hành. Người dùng đồng ý, đặc biệt, để:</p>
                <ul>
                    <li>Không làm quá tải trang web;</li>
                    <li>Tôn trọng hình ảnh và uy tín của các người dùng khác và/hoặc Dealio và không gây hại đến lợi ích của họ (đạo đức, thương mại, v.v.);</li>
                    <li>Không làm hư hại trang web bằng cách không thay đổi hoặc sửa đổi bất kỳ phần nào của trang web hoặc hạn chế việc sử dụng của nó bằng bất kỳ phương tiện nào, không làm giả bất kỳ đề cập hoặc yếu tố nào của trang web, không cạo nội dung và cơ sở dữ liệu của trang web, đặc biệt là sử dụng các chương trình tự động, không phân phối nội dung của trang web trên bất kỳ phương tiện nào khác mà không có sự cho phép trước bằng văn bản của Dealio, không có hành vi có khả năng làm gián đoạn, tạm ngưng trang web, không xâm nhập hoặc cố gắng xâm nhập vào các hệ thống của trang web;</li>
                    <li>Không xâm nhập vào hệ thống máy tính, không thay đổi nội dung hoặc thực hiện bất kỳ hành vi nào liên quan đến an ninh mạng và tội phạm máy tính như được định nghĩa bởi luật pháp Việt Nam, đặc biệt là Luật An Ninh Mạng và Luật An Ninh Thông Tin Mạng.</li>
                    <li>Không phân phối hoặc cố gắng phân phối virus máy tính hoặc bất kỳ yếu tố độc hại nào khác thông qua trang web;</li>
                    <li>Tôn trọng quyền của bên thứ ba, đặc biệt bằng cách không sử dụng, sao chép, phân phối, sửa đổi, đại diện hoặc phân phối văn bản, ảnh, hoặc bất kỳ yếu tố hoặc tác phẩm nào được bảo vệ bởi quyền tác giả, luật thương hiệu, hoặc bất kỳ quyền sở hữu trí tuệ nào khác thuộc về bên thứ ba;</li>
                    <li>Không xâm nhập vào hệ thống máy tính, không thay đổi nội dung hoặc thực hiện bất kỳ hành vi nào bị phạt theo các điều 323-1 đến 323-7 của Bộ luật Hình sự ("hacking");</li>
                    <li>Không gửi tin nhắn hàng loạt không được yêu cầu ("spamming").</li>
                    <li>Không tham gia vào các hoạt động có thể gây bất ổn xã hội hoặc đe dọa an ninh quốc gia như được nêu trong Điều 18.1 của Luật An Ninh Mạng.</li>
                </ul>

                <h3>4.2. Nghĩa Vụ của Thành Viên</h3>
                <p>Các thành viên đồng ý tuân thủ tất cả các Điều Khoản này và các luật và quy định hiện hành, đặc biệt là:</p>
                <ul>
                    <li>Không sử dụng trang web và/hoặc các chức năng của nó cho các mục đích bất hợp pháp bao gồm nhưng không giới hạn ở việc phân phối nội dung bất hợp pháp, hàng giả, sản phẩm bất hợp pháp, ma túy, tài liệu khiêu dâm, vật liệu nguy hiểm, vũ khí/đạn dược/chất nổ, sản phẩm động vật hạn chế, hàng bị đánh cắp, thuốc kê đơn.</li>
                    <li>Không tự quảng cáo dịch vụ, ưu đãi hoặc các hoạt động khác của họ, cũng như của người quen.</li>
                    <li>Không mạo danh bất kỳ bên thứ ba nào và/hoặc sử dụng hoặc cố gắng sử dụng hồ sơ của thành viên khác.</li>
                    <li>Không cho phép người khác sử dụng hồ sơ của họ và không chuyển hồ sơ của họ cho bên thứ ba.</li>
                    <li>Không tránh một biện pháp trừng phạt bằng cách tạo tài khoản mới trên trang web.</li>
                    <li>Không có nhiều tài khoản trên trang web.</li>
                    <li>Xuất bản thông tin chính xác, đáng tin cậy và rõ ràng bao gồm nhưng không giới hạn ở tiêu đề, mô tả, liên kết, hình ảnh, bình luận.</li>
                    <li>Không gửi nhiều bình luận tương tự.</li>
                    <li>Không gửi nhiều tin nhắn riêng tư tương tự.</li>
                    <li>Không gửi đề xuất tài trợ và không gợi ý hoặc ám chỉ tài trợ trong các bình luận, ưu đãi, thảo luận hoặc tin nhắn riêng tư không được yêu cầu.</li>
                    <li>Xuất bản bình luận tuân thủ các tiêu chuẩn cao về lịch sự, đạo đức và tôn trọng lẫn nhau.</li>
                    <li>Không phân phối hoặc xuất bản tài liệu hoặc đưa ra tuyên bố có khả năng xúc phạm người khác, gây tranh cãi (trolling), trái với trật tự công cộng hoặc đạo đức, hoặc vi phạm quyền hoặc phẩm giá của bên thứ ba.</li>
                    <li>Không đưa ra các tuyên bố phỉ báng, xúc phạm, bôi nhọ, phân biệt chủng tộc, bài ngoại, khiêu dâm, hoặc các tuyên bố nói chung trái với đạo đức và đạo đức và/hoặc trật tự công cộng.</li>
                    <li>Không xúi giục thực hiện một tội ác, một tội phạm, hoặc một hành động khủng bố hoặc đưa ra lời xin lỗi cho tội ác chiến tranh hoặc tội ác chống lại loài người.</li>
                    <li>Đảm bảo rằng tất cả nội dung đăng tải tuân thủ các luật và quy định của Việt Nam, tránh nội dung không tôn trọng Nhà nước, văn hóa hoặc lịch sử của Việt Nam, theo yêu cầu của Điều 19.1 của Nghị định 53/2022/NĐ-CP.</li>
                </ul>

                <h3>4.3. Điều Kiện Đăng Ưu Đãi</h3>
                <p>Để tôn trọng tinh thần cộng đồng của trang web, các thành viên đồng ý không đăng các ưu đãi được liệt kê là bị loại trừ trong Quy Tắc Đăng Ưu Đãi.</p>

                <h3>4.4. Điều Kiện Bình Chọn Ưu Đãi</h3>
                <p>Các thành viên đồng ý, khi bình chọn để quảng bá một ưu đãi, thực hiện một cách trung thực để không làm thay đổi tiến trình hoặc suy giảm của một ưu đãi có liên quan.</p>

                <h2>5. Liên Kết, Trách Nhiệm, Bảo Đảm</h2>

                <h3>5.1. Liên Kết Siêu Văn Bản</h3>
                <p>Trang web chứa các liên kết siêu văn bản, thường là các ưu đãi được thêm bởi thành viên, trỏ đến các tài nguyên hoặc trang web do bên thứ ba quản lý, không thuộc sở hữu hoặc được kiểm soát bởi Dealio. Các trang web mà các liên kết siêu văn bản này dẫn đến, trừ khi có quy định khác, độc lập với trang web Dealio.</p> 
                <p>Dealio không kiểm soát nội dung hoặc thực tiễn của các trang web bên thứ ba và từ chối bất kỳ trách nhiệm nào liên quan đến điều này. Dealio không thể chịu trách nhiệm cho nội dung của các trang web này, và việc thông tin về các trang web này xuất hiện trên trang web không liên quan đến trách nhiệm của nó.</p>
                <p>Người dùng chịu trách nhiệm duy nhất và mọi rủi ro phát sinh từ việc tham khảo và sử dụng các trang web bên thứ ba này. Các liên kết này không cấu thành, trong bất kỳ trường hợp nào, sự chứng nhận hoặc đối tác giữa Dealio và các trang web này.</p>
                <p>Do đó, Dealio không đảm bảo, đặc biệt là:</p>
                <ul>
                    <li>Tính chính xác, thời gian, chất lượng, hoàn chỉnh và đầy đủ của nội dung các ưu đãi của sản phẩm hoặc dịch vụ được lập chỉ mục trên các trang web này;</li>
                    <li>Sự liên quan và hoàn chỉnh của các trang web được lập chỉ mục;</li>
                    <li>Khó khăn trong truy cập và hoạt động của các trang web này.</li>
                </ul>
                <p>Do đó, Dealio không thể chịu trách nhiệm trong trường hợp xảy ra tranh chấp về bất kỳ bản chất nào phát sinh giữa một trang web được lập chỉ mục trên trang web và người dùng. Các nhà vận hành của các trang web được Dealio tham chiếu chịu trách nhiệm duy nhất về việc tuân thủ tất cả các quy định áp dụng cho các sản phẩm hoặc dịch vụ được cung cấp cho người dùng.</p>
                <p>Không có sự cho phép trước của Dealio, việc cung cấp trên một trang web bên thứ ba một liên kết siêu văn bản trỏ đến trang web hiện tại là bị cấm. Nếu bạn muốn thiết lập một liên kết siêu văn bản đến trang web, bạn phải liên hệ với quản trị viên trang web bằng cách sử dụng mẫu liên hệ có sẵn trong phần "Liên hệ" trên trang chủ.</p> 
                <p>Trong mọi trường hợp, các trang web của Dealio không nên được nhúng vào các trang của trang web khác. Mọi thứ phải được thực hiện để chỉ rõ rằng người dùng đang ở trên trang web Dealio và cho phép họ điều hướng tự do. Trong bất kỳ trường hợp nào, Dealio không chịu trách nhiệm cho các liên kết đến trang web của nó.</p>

                <h3>5.2. Trách Nhiệm và Bảo Đảm của Người Dùng và Thành Viên</h3>
                <p>Người dùng chịu trách nhiệm duy nhất về:</p>
                <ul>
                    <li>Việc sử dụng trang web và các dịch vụ của nó.</li>
                </ul>
                <p>Các thành viên chịu trách nhiệm duy nhất về:</p>
                <ul>
                    <li>Các ưu đãi và/hoặc bình luận mà họ xuất bản hoặc phân phối trên trang web. Trong điều này, họ đảm bảo rằng họ có tất cả các quyền, giấy phép, sự đồng ý và ủy quyền cần thiết cho thông tin mà họ phân phối và/hoặc xuất bản trên trang web. Họ đảm bảo rằng họ tuân thủ Luật An Ninh Mạng của Việt Nam theo Điều 19.1 của Nghị định 53/2022. Cuối cùng, họ tuyên bố và đảm bảo rằng thông tin được đưa lên mạng không phải là bí mật và không vi phạm bất kỳ nghĩa vụ hợp đồng nào.</li>
                </ul>
                <p>Người dùng bảo đảm cho Dealio khỏi bất kỳ thiệt hại nào mà nó phải chịu và khỏi bất kỳ hành động trách nhiệm nào sẽ được đưa ra chống lại nó dựa trên việc vi phạm các Điều Khoản này và/hoặc bất kỳ quyền nào của bên thứ ba. Trong trường hợp gian lận đã được chứng minh, trang web tự cho phép mình thông tin tất cả các thông tin cần thiết cho các cơ quan có thẩm quyền chịu trách nhiệm đàn áp các gian lận và vi phạm đó.</p>
                <p>Người dùng đặc biệt bảo đảm cho Dealio khỏi bất kỳ khiếu nại, hành động pháp lý hoặc yêu cầu nào, bao gồm nhưng không giới hạn ở, (i) bất kỳ hành động nào liên quan đến thông tin được đưa lên mạng bởi người dùng, (ii) bất kỳ việc sử dụng nào của người dùng đối với thông tin trên trang web, (iii) hoặc bất kỳ vi phạm nào của các Điều Khoản này.</p>

                <h2>6. Kiểm Duyệt và Chế Tài</h2>

                <h3>6.1. Kiểm Duyệt Ưu Đãi và Chế Tài</h3>
                <p>Dealio bảo lưu quyền tùy ý kiểm duyệt, tức là sửa đổi, di chuyển hoặc xóa các ưu đãi và/hoặc nội dung được thành viên đăng tải. Trong trường hợp một thành viên vi phạm một trong các điều kiện ưu đãi được quy định trong mục 4, thành viên có thể được cảnh báo, nhưng không nhất thiết, bị cấm truy cập các dịch vụ của trang web, đặc biệt trong trường hợp vi phạm lặp đi lặp lại.</p>

                <h3>6.2. Kiểm Duyệt Bình Luận và Chế Tài</h3>
                <p>Dealio có thể kiểm duyệt, tức là sửa đổi hoặc xóa, các tuyên bố được thực hiện bởi các thành viên và chặn truy cập vào các bình luận và dịch vụ cho các thành viên đưa ra các tuyên bố có khả năng xúc phạm người khác hoặc vi phạm quyền của họ, hoặc không tuân thủ các Điều Khoản này, đặc biệt là những điều trong mục 4 trên. Thành viên có thể nhận cảnh báo trước nhưng không nhất thiết.</p> 
                <p>Trong trường hợp vi phạm lặp đi lặp lại nhưng không giới hạn ở điều này, thành viên vi phạm có thể bị cấm truy cập các dịch vụ của trang web. Dealio bảo lưu quyền tùy ý từ chối một số liên kết siêu văn bản xuất hiện trong các bình luận. Quyền xóa này áp dụng cho tất cả các liên kết trên trang web.</p>

                <h2>7. Xóa Hồ Sơ</h2>

                <h3>7.1. Xóa Hồ Sơ</h3>

                <h4>7.1.1. Theo Sáng Kiến Của Thành Viên</h4>
                <p>Một thành viên có thể yêu cầu xóa hồ sơ của họ bất cứ lúc nào bằng cách yêu cầu gửi đến dealio.vn@gmail.com. Việc xóa hồ sơ là vĩnh viễn, và dữ liệu cá nhân liên kết với nó không thể được khôi phục bởi thành viên. Tuy nhiên, các ưu đãi, bình chọn cho ưu đãi, chủ đề và bình luận do thành viên để lại trên trang web sẽ được giữ lại và ẩn danh. Hồ sơ sẽ được giữ lại trong bộ nhớ của trang web trong thời gian được pháp luật cho phép.</p>

                <h4>7.1.2. Theo Sáng Kiến Của Dealio</h4>
                <p>Trong trường hợp một thành viên vi phạm một trong các nghĩa vụ của họ, đặc biệt là những nghĩa vụ được quy định trong mục 4 trên, Dealio bảo lưu quyền tùy ý tạm ngưng hoặc xóa tài khoản của thành viên ngay lập tức, không cần thông báo hoặc bồi thường. Việc xóa hồ sơ là vĩnh viễn, và dữ liệu cá nhân liên kết với nó không thể được khôi phục bởi thành viên. Dealio không thể chịu trách nhiệm cho hậu quả của việc xóa này. Tuy nhiên, các ưu đãi, bình chọn cho ưu đãi, chủ đề và bình luận do thành viên để lại trên trang web sẽ được giữ lại và ẩn danh. Hồ sơ sẽ được giữ lại trong bộ nhớ của trang web trong thời gian được pháp luật cho phép.</p>

                <h2>8. Trách Nhiệm của Dealio, Báo Cáo</h2>

                <h3>8.1. Trách Nhiệm của Dealio</h3>
                <p>Trang web chỉ là một không gian cộng đồng để chia sẻ các ưu đãi khuyến mãi khác nhau. Dealio chỉ cung cấp cho người dùng một nền tảng dịch vụ, cũng như các công cụ và phương tiện kỹ thuật để chia sẻ ưu đãi. Dealio hoạt động chỉ như một nhà cung cấp nội dung, cung cấp lưu trữ kỹ thuật đơn giản và khả năng sẵn có của không gian theo luật và quy định của Việt Nam như được nêu trong Luật An Ninh Mạng và Nghị định 53/2022.</p>
                <p>Dealio thực hiện kiểm duyệt trước để nâng cao chất lượng tổng thể của nội dung được thành viên đăng tải. Tuy nhiên, bất kỳ vấn đề nào không được phát hiện trong quá trình kiểm duyệt, bao gồm vi phạm pháp luật hoặc các Điều Khoản này, đều thuộc trách nhiệm duy nhất của người dùng. Dealio không thể chịu trách nhiệm cho bất kỳ nội dung như vậy. Do đó, nó không thể chịu trách nhiệm cho bất kỳ nội dung bất hợp pháp hoặc không tuân thủ nào có thể được đăng tải. Bất kỳ việc định dạng hoặc bố cục nào của các yếu tố được tạo tự động bởi trang web không ngụ ý sự ủng hộ hoặc tham gia của Dealio trong việc đăng tải của chúng.</p>
                <p>Sự lựa chọn các danh mục phân loại ưu đãi, cũng như tổ chức tổng thể và hoạt động của trang web, chỉ nhằm tạo thuận lợi cho việc sử dụng của nó và không nên được diễn giải là các yếu tố cho thấy quyết định của bất kỳ chính sách biên tập nào. Chỉ có người dùng quyết định về các ưu đãi được đăng tải. Các thuật toán của trang web sau đó tự động phân loại các ưu đãi và sự xuất hiện của chúng trên trang chủ của trang web.</p>
                <p>Cuối cùng, Dealio không thể chịu trách nhiệm đối với người dùng về:</p>
                <ul>
                    <li>Bất kỳ thiệt hại gián tiếp nào mà họ có thể phải chịu, bao gồm bất kỳ mất lợi nhuận nào (dù trực tiếp hay gián tiếp), mất khách hàng hoặc danh tiếng kinh doanh, hoặc bất kỳ mất dữ liệu nào họ có thể gặp phải;</li>
                    <li>Bất kỳ mất mát hoặc thiệt hại nào họ có thể phải chịu do:</li>
                    <li>Sự tin tưởng vào tính hoàn chỉnh, chính xác hoặc tồn tại của bất kỳ quảng cáo, thông tin hoặc video nào trên trang web hoặc kết quả từ bất kỳ mối quan hệ hoặc giao dịch nào được thực hiện giữa người dùng và bất kỳ nhà quảng cáo hoặc đối tác nào mà quảng cáo hoặc tác phẩm của họ xuất hiện trên trang web;</li>
                    <li>Bất kỳ thay đổi nào Dealio có thể thực hiện đối với trang web nói chung, hoặc bất kỳ việc xóa tạm thời hoặc vĩnh viễn của một ưu đãi;</li>
                    <li>Việc xóa, hư hỏng hoặc không lưu trữ hồ sơ hoặc bất kỳ dữ liệu truyền thông nào khác được lưu trữ hoặc truyền đi;</li>
                    <li>Mối quan hệ của họ với các thành viên khác mà họ tương tác;</li>
                    <li>Việc truy cập hoặc sử dụng, hoặc không thể truy cập hoặc sử dụng trang web nói chung;</li>
                    <li>Việc không thể giữ mật khẩu hoặc thông tin cung cấp trong quá trình đăng ký an toàn và bảo mật;</li>
                    <li>Việc không thể xóa hoặc không thể lưu trữ hoặc truyền thông tin, bình luận hoặc tin nhắn;</li>
                    <li>Bất kỳ thông tin và/hoặc bình luận nào trên trang web;</li>
                    <li>Bất kỳ hành vi của bên thứ ba nào trên trang web nói chung, bao gồm nhưng không giới hạn ở bất kỳ hành vi phỉ báng, xúc phạm hoặc bất hợp pháp nào của các thành viên khác hoặc bất kỳ bên thứ ba nào;</li>
                    <li>Bất kỳ nội dung nào được chèn vào các tin nhắn được gửi thông qua trang web.</li>
                </ul>

                <h3>8.2. Báo Cáo Ưu Đãi</h3>
                <p>Trang web Dealio cho phép các thành viên của mình báo cáo các ưu đãi và bình luận được đăng bởi các thành viên khác nếu họ tin rằng một trong các quy tắc của các Điều Khoản của trang web, đặc biệt là những điều trong mục 4 trên, đã bị vi phạm. Các ưu đãi và bình luận được báo cáo sẽ được các quản trị viên và/hoặc kiểm duyệt viên phân tích để xác định xem bình luận hoặc ưu đãi được báo cáo có thực sự vi phạm các Điều Khoản của trang web hay không. Dealio bảo lưu quyền chặn, sửa đổi hoặc xóa các bình luận và ưu đãi được báo cáo. Dealio bảo lưu quyền hạn chế hoặc chặn truy cập vào toàn bộ hoặc một phần các dịch vụ đối với các thành viên lạm dụng hệ thống báo cáo này.</p>

                <h2>9. Dữ Liệu Cá Nhân và Định Danh</h2>

                <h3>9.1. Dữ Liệu Cá Nhân</h3>

                <h4>9.1.1. Thu Thập và Sử Dụng Dữ Liệu Cá Nhân</h4>
                <p>Dealio cam kết bảo vệ dữ liệu cá nhân của người dùng. Dữ liệu cá nhân đề cập đến bất kỳ thông tin nào có thể nhận dạng một cá nhân, chẳng hạn như nhưng không giới hạn ở tên, địa chỉ email, số điện thoại.</p>

                <h4>9.1.2. Dữ Liệu Được Thu Thập</h4>
                <ul>
                    <li><strong>Dữ Liệu Đăng Ký:</strong> Khi người dùng tạo tài khoản, Dealio thu thập thông tin như tên, địa chỉ email và mật khẩu.</li>
                    <li><strong>Dữ Liệu Hồ Sơ:</strong> Người dùng có thể cung cấp thêm thông tin cho hồ sơ của họ, bao gồm hình ảnh hồ sơ và mô tả cá nhân.</li>
                    <li><strong>Dữ Liệu Sử Dụng:</strong> Dealio thu thập thông tin về cách người dùng tương tác với trang web, bao gồm các trang đã xem, các liên kết đã nhấp và các ưu đãi đã đăng.</li>
                    <li><strong>Dữ Liệu Giao Tiếp:</strong> Điều này bao gồm thông tin được thu thập khi người dùng giao tiếp với Dealio, chẳng hạn như thông qua các yêu cầu dịch vụ khách hàng hoặc biểu mẫu phản hồi.</li>
                </ul>

                <h4>9.1.3. Mục Đích Thu Thập Dữ Liệu</h4>
                <p>Dealio thu thập và xử lý dữ liệu cá nhân cho các mục đích sau:</p>
                <ul>
                    <li><strong>Quản Lý Tài Khoản:</strong> Để tạo và quản lý tài khoản người dùng.</li>
                    <li><strong>Cung Cấp Dịch Vụ:</strong> Để cung cấp và cải thiện các dịch vụ của Dealio, bao gồm quản lý các ưu đãi, bình chọn và bình luận.</li>
                    <li><strong>Giao Tiếp:</strong> Để gửi các tin nhắn hành chính và trả lời các yêu cầu của người dùng.</li>
                    <li><strong>Bảo Mật:</strong> Để bảo vệ an ninh của trang web và ngăn chặn các hoạt động gian lận.</li>
                    <li><strong>Tuân Thủ Pháp Luật:</strong> Để tuân thủ các nghĩa vụ pháp lý và trả lời các yêu cầu từ các cơ quan chính phủ.</li>
                </ul>

                <h4>9.1.4. Chia Sẻ Dữ Liệu</h4>
                <p>Dealio có thể chia sẻ dữ liệu cá nhân với bên thứ ba trong các trường hợp sau:</p>
                    <ul>
                        <li><strong>Nhà Cung Cấp Dịch Vụ:</strong> Dealio có thể chia sẻ dữ liệu với các nhà cung cấp dịch vụ bên thứ ba thực hiện dịch vụ thay mặt cho Dealio, chẳng hạn như xử lý thanh toán và phân tích dữ liệu.</li>
                        <li><strong>Yêu Cầu Pháp Lý:</strong> Dealio có thể tiết lộ dữ liệu cá nhân nếu được yêu cầu làm như vậy bởi luật pháp hoặc để đáp ứng yêu cầu hợp pháp từ cơ quan thực thi pháp luật hoặc quan chức chính phủ.</li>
                        <li><strong>Chuyển Giao Kinh Doanh:</strong> Trong trường hợp sáp nhập, mua lại hoặc bán tất cả hoặc một phần tài sản của Dealio, dữ liệu cá nhân có thể được chuyển giao cho thực thể tiếp nhận.</li>
                    </ul>
                <h4>9.1.5. Bảo Mật Dữ Liệu</h4>
                <p>Dealio thực hiện các biện pháp kỹ thuật và tổ chức thích hợp để bảo vệ dữ liệu cá nhân chống lại sự phá hủy, mất mát, thay đổi, tiết lộ trái phép hoặc truy cập ngẫu nhiên hoặc bất hợp pháp. Tuy nhiên, không có dịch vụ dựa trên internet nào có thể hoàn toàn an toàn, và Dealio không thể đảm bảo bảo mật tuyệt đối của dữ liệu cá nhân. Dealio tuân thủ các quy định của Việt Nam về bảo vệ dữ liệu cá nhân. Dữ liệu của người dùng sẽ được xử lý theo luật của Việt Nam, bao gồm các yêu cầu lưu trữ dữ liệu như được quy định trong Nghị định 53/2022.</p>

                <h4>9.1.6. Lưu Trữ Dữ Liệu</h4>
                <p>Dealio lưu trữ dữ liệu cá nhân trong thời gian cần thiết để hoàn thành các mục đích mà nó được thu thập và để tuân thủ các nghĩa vụ pháp lý. Người dùng có thể yêu cầu xóa dữ liệu của họ bằng cách liên hệ với Dealio thông qua biểu mẫu liên hệ.</p>

                <h4>9.1.7. Quyền Của Người Dùng</h4>
                <p>Người dùng có các quyền sau đối với dữ liệu cá nhân của họ:</p>
                <ul>
                    <li><strong>Truy Cập:</strong> Người dùng có thể yêu cầu truy cập vào dữ liệu cá nhân của họ do Dealio nắm giữ.</li>
                    <li><strong>Sửa Đổi:</strong> Người dùng có thể yêu cầu sửa đổi dữ liệu cá nhân không chính xác hoặc không đầy đủ.</li>
                    <li><strong>Xóa:</strong> Người dùng có thể yêu cầu xóa dữ liệu cá nhân của họ.</li>
                    <li><strong>Hạn Chế:</strong> Người dùng có thể yêu cầu hạn chế xử lý dữ liệu cá nhân của họ.</li>
                    <li><strong>Di Chuyển:</strong> Người dùng có thể yêu cầu một bản sao dữ liệu cá nhân của họ trong một định dạng có cấu trúc, thông dụng và có thể đọc bằng máy.</li>
                    <li><strong>Phản Đối:</strong> Người dùng có thể phản đối việc xử lý dữ liệu cá nhân của họ cho các mục đích nhất định.</li>
                </ul>
                <p>Để thực hiện các quyền này, người dùng có thể liên hệ với Dealio thông qua biểu mẫu liên hệ trên trang web.</p>

                <h4>9.1.8. Thay Đổi Chính Sách Này</h4>
                <p>Dealio bảo lưu quyền cập nhật chính sách bảo mật này bất cứ lúc nào. Người dùng sẽ được thông báo về bất kỳ thay đổi quan trọng nào thông qua trang web hoặc qua email.</p>

                <h2>10.Thảo Luận</h2>

                <h3>10.1. THẢO LUẬN</h3>
                <p>Các Điều Khoản này áp dụng cho việc sử dụng và tạo các chủ đề thảo luận và các bình luận của chúng.</p>

                <h2>11. Tính Vô Hiệu, Luật Áp Dụng, Thẩm Quyền Thích Hợp</h2>

                <h3>11.1. TÍNH VÔ HIỆU</h3>
                <p>Nếu một hoặc nhiều điều khoản của các Điều Khoản này bị giữ là vô hiệu hoặc được tuyên bố như vậy bởi một luật, quy định hoặc theo quyết định cuối cùng của tòa án có thẩm quyền, các điều khoản khác sẽ giữ nguyên hiệu lực và phạm vi của chúng.</p>

                <h3>11.2. LUẬT ÁP DỤNG, THẨM QUYỀN THÍCH HỢP</h3>
                <p>Việc sử dụng và hoạt động của trang web phải tuân theo luật pháp Việt Nam và luật quốc tế. Hiệu lực, hiệu lực và giải thích của các Điều Khoản này áp dụng cho trang web cũng phải tuân theo luật pháp Việt Nam và luật quốc tế.</p>
                <p><strong>TRONG PHẠM VI ĐƯỢC PHÁP LUẬT ĐỊA PHƯƠNG ÁP DỤNG CHO NGƯỜI DÙNG CHO PHÉP, BẤT KỲ TRANH CHẤP NÀO PHÁT SINH TỪ HIỆU LỰC, GIẢI THÍCH VÀ/HOẶC HIỆU LỰC CỦA CÁC ĐIỀU KHOẢN NÀY MÀ CÁC BÊN KHÔNG THỂ GIẢI QUYẾT THÂN THIỆN SẼ ĐƯỢC ĐỆ TRÌNH LÊN THẨM QUYỀN DUY NHẤT CỦA CÁC TÒA ÁN VIỆT NAM, NGAY CẢ TRONG TRƯỜNG HỢP NHIỀU BỊ ĐƠN, CÁC THỦ TỤC KHẨN CẤP HOẶC THÔNG BÁO BÊN THỨ BA.</strong></p>
                            
            </div>



            <div className="terms-and-conditions">
                <h1>TERMS AND CONDITIONS FOR USE</h1>
                <div className="sub-heading">Terms and Conditions for Use of Dealio</div>
                <h2>1. Definitions and Purpose</h2>
                <p>Dealio is an online platform providing a community space for sharing various promotional offers. Through its site, Dealio provides users with a temporary and revocable platform where members can:</p>
                <ul>
                    <li>Create and manage their personal profiles, view their offers on the site, and view offers from other members.</li>
                    <li>Post offers.</li>
                    <li>Vote for or against offers.</li>
                    <li>Engage in discussions with other members by posting comments.</li>
                    <li>Receive all communications from Dealio intended for members, such as administrative messages, which the member expressly agrees to receive.</li>
                </ul>
                <p>These functions are provided as-is and are not limited to the aforementioned list. Dealio reserves the right to modify or delete any of these functions at any time, without prior notice or liability. Access to the Dealio website and the use of its content by users are subject to these Terms and Conditions of Use.</p>
                <p className="important-notice"><strong>IMPORTANT NOTICE FOR USERS</strong></p>
                <p className="uppercase">USING THE DEALIO WEBSITE OR ANY FEATURE OF THE DEALIO WEBSITE IMPLIES PRIOR, FULL, AND COMPLETE ACCEPTANCE BY USERS OF THESE TERMS AND CONDITIONS OF USE. BY CREATING A PROFILE, USERS ACKNOWLEDGE THAT THEY ACCEPT THESE TERMS AND CONDITIONS OF USE BY CLICKING ON THE "SIGN UP" BUTTON. THESE TERMS AND CONDITIONS ARE REGULARLY UPDATED, AND USERS ARE INVITED TO REVIEW THEM AT EACH OF THEIR VISITS.</p>
                <h3>1.1. DEFINITIONS</h3>
                <p>The definitions below in the singular also apply to the plural, and vice versa, depending on the context.</p>
                <ul>
                    <li><strong>"Terms and Conditions"</strong> refers to these terms of use applicable to all users of the site.</li>
                    <li><strong>"Content"</strong> refers to any visual or textual element published on the site by the member and associated with their profile.</li>
                    <li><strong>"Dealio"</strong> refers to the company operating the platform www.dealio.asia, contactable via dealio.vn@gmail.com.</li>
                    <li><strong>"Merchant"</strong> refers to professional online or physical retailers whose commercial offers can be published by members as offers.</li>
                    <li><strong>"Offer" or "Deal"</strong> refers to a promotional offer consisting of a hyperlink redirecting to an online or physical store of a third-party commercial company.</li>
                    <li><strong>"Profile"</strong> refers to the personal page of members on the site, accessible only after the member has logged in via their email and password or via social media. It is from their profile that the member can enrich the information they wish to share with other members, access their settings, and manage their space.</li>
                    <li><strong>"Services"</strong> refers to the various features offered by the site to users, such as voting for offers or participating in discussions.</li>
                    <li><strong>"Site"</strong> refers to the Dealio website, with the main address www.dealio.asia. The site includes all IT components (including software and developments, web pages, source and object code, domain names, preparatory design work, specifications, preliminary studies, user documentation, database), graphic design (including projects, models, prototypes, plans), content (including textual, graphic, sound, photographic, multimedia elements), and functionalities, as well as all related rights.</li>
                    <li><strong>"User"</strong> refers to any internet user, whether registered or not on the site, as per section 4 of these Terms and Conditions.</li>
                </ul>
                <h3>1.2. PURPOSE</h3>
                <p>These Terms and Conditions govern the conditions and modalities of use by users and members of the site's services, as well as define the rights and obligations of users and members who are connected via the site.</p>

                <h2>2. Accessibility and Profile</h2>
                <h3>2.1. ACCESS TO THE SITE AND PROFILE</h3>
                <p>The site is accessible via the URL: www.dealio.asia</p>
                <p>Access to profiles is open to all registered internet users as per section 4: Member Registration and Profile Creation. Once registered, members can log in to their profiles via a social media identification method.</p>
                <p>The services of the site may evolve with updates to meet new technical, commercial, or legal requirements. Consequently, Dealio cannot be held responsible for such evolution, including the removal of any existing services at the time of registration on the site.</p>
                <h3>2.2. MEMBER REGISTRATION AND PROFILE CREATION</h3>
                <p>Registration on the profile is done via the site. It is free, except for connection fees, which are the sole responsibility of users. It implies full, complete, and unreserved acceptance of these Terms and Conditions.</p>
                <p>Any person aged thirteen (13) years or older can become a member. Dealio does not intervene in the creation of profiles and does not verify the accuracy, reliability, or timeliness of the information provided by members. Consequently, Dealio cannot be held responsible for the information contained in profiles.</p>
                <p>Members are expected to adhere to high standards of courtesy, decency, and mutual respect. Members agree not to use pseudonyms or avatars that may offend others, are contrary to public order or morals, or infringe on the rights of third parties, or that are promotional in nature. Dealio reserves the right to moderate pseudonyms and avatars used by members and may refuse a registration. A person cannot have multiple profiles on the site.</p>
                <h3>2.3. SITE ACCESSIBILITY</h3>
                <p>To access the site, users must:</p>
                <ul>
                    <li>Accept cookies and enable JavaScript.</li>
                    <li>Be of legal age or have prior authorization from their legal representative.</li>
                </ul>
                <p>Access to the site can be made from a computer, smartphone, or tablet connected to www.dealio.asia by any means of telecommunication. Dealio does not provide users with any equipment, telephone installation, terminal equipment, consumables, or other materials. Whatever the mode of connection chosen, connection fees are the responsibility of users, as well as any amount, fee, registration, tax, or other charges that may be requested by access providers to telecommunication networks connecting users to the site.</p>
                <p>Dealio makes its best efforts to ensure the availability of the site. However, users are informed that the site is not subject to any obligation of availability, accessibility, or performance. Therefore, users cannot claim any defect that prevents the use of the site or degrades the user experience.</p>
                <p>The site is online 24/7, but Dealio reserves the right to modify or temporarily or permanently interrupt access to the site for maintenance, updates, improvements, or to evolve its content and/or presentation without notice or compensation. Dealio will inform members in advance of such operations whenever possible.</p>
                <p>Dealio reserves the right to suspend without notice multiple accounts of the same member or account of members not respecting these Terms and Conditions, including but not restricted to: posting fake or misleading content, engaging in abusive or inappropriate behavior, using the site for illegal activities, impersonating another member, self-promotion, circumventing sanctions, spamming, harming the site or its users, infringing on intellectual property rights, and posting defamatory, insulting, racist, xenophobic, or pornographic content.</p>

                <h2>3. Intellectual Property Rights</h2>
                <h3>3.1. INTELLECTUAL PROPERTY RIGHTS</h3>
                <h4>3.1.1. Intellectual Property Rights of Users</h4>
                <p>Users declare and guarantee that they are the sole owners of all intellectual property rights and/or image rights on the information (texts, images, drawings, photographs, comments, trademarks, social names, etc.) and/or comments published or distributed on the site. If not, they must have all the necessary rights, authorizations, and assignments for the publication and display of said information and the granting of rights to Dealio as described below. Members agree to check the terms of use and reproduction of the content of the merchant's site from which the commercial offer originates before publishing any offer.</p>
                <p>They also guarantee that the information and/or comments and/or offers published or distributed do not constitute, in whole or in part, acts of counterfeiting, unfair competition, commercial parasitism, or infringement of the image rights of persons or property. Users retain all possible intellectual property rights on the information (texts, images, drawings, photographs, comments, trademarks, social names, etc.) they distribute and/or publish on their profile.</p>
                <p>Users grant Dealio a license to use the content they publish on the site, except for private messages, for the entire duration of copyright protection in Vietnam, worldwide, free of charge, irrevocable, non-exclusive, and transferable. This usage license includes the right for Dealio to use, reproduce, represent, adapt, translate, or sublicense the content published by members, except for private messages, in whole or in part, in all formats, by any means, on the site or on all advertising and promotional media directly related to the site, without limitation of copies of the relevant media, and promotional and advertising exploitation methods directly related to the site (posters, billboards, particularly all display media, any advertising object not intended for direct sale), as well as for the following media and means: press (newspapers, periodicals), paper editions (catalogs, brochures, leaflets, books, cards, leaflets…), multimedia and electronic editions (internet, intranet, …), commercial documents (institutional brochures, etc…).</p>
                <p>If Dealio suspects that the information and/or comments and/or offers from members infringe the image rights and/or intellectual property rights of third parties, it reserves the right to:</p>
                <ul>
                    <li>Immediately delete, without notice or compensation, any contentious content, information, and/or comments;</li>
                    <li>Suspend or delete the member's profile under the conditions of section 6 below;</li>
                    <li>Restrict or block access to all or part of the services.</li>
                </ul>
                <h4>3.1.2. Intellectual Property Rights of Dealio</h4>
                <p>The site and its elements are protected by intellectual property rights belonging to Dealio. Users are prohibited from engaging in any actions that directly or indirectly infringe on Dealio's intellectual property rights. Thus, users are prohibited from publishing, reproducing, distributing, representing, adapting, disassembling, decompiling, in Vietnam or abroad, all or part of the site's content; using Dealio's technology or know-how for commercial purposes; using Dealio's trademarks and/or images to create or offer services similar to or competing with the site; or registering identical or similar trademarks.</p>
                <p>No transfer of Dealio's intellectual property rights is granted to users. Users are only granted a non-exclusive, free, revocable, and non-transferable right to access and use the site's services and functionalities for the duration of their use of the site's services, as expressly provided in these Terms and Conditions. Any use of data or information from the Dealio site must mention Dealio as the source of information and is subject to obtaining prior formal written authorization from Dealio's administrators.</p>

                <h2>4. User Obligations, Offer Conditions, and Voting</h2>
                <h3>4.1. USER OBLIGATIONS</h3>
                <p>Users agree to comply with all these Terms and Conditions and applicable laws and regulations. Users agree, in particular, to:</p>
                <ul>
                    <li>Not overload the site;</li>
                    <li>Respect the image and reputation of other users and/or Dealio and not harm their interests (moral, commercial, etc.);</li>
                    <li>Not damage the site by not modifying or altering any part of the site or restricting its use by any means, not falsifying any mention or element of the site, not scraping the site's content and database, particularly using automated programs, not distributing the site's content on any other medium without Dealio's prior written authorization, not having behavior likely to interrupt, suspend the site, not infiltrate or attempt to infiltrate the site's systems;</li>
                    <li>Not infiltrate a computer system, not alter content, or commit any offenses related to cybersecurity and computer crimes as defined by Vietnamese laws, specifically the Cybersecurity Law and the Law on Cyberinformation Security.</li>
                    <li>Not distribute or attempt to distribute computer viruses or any other harmful elements through the site;</li>
                    <li>Respect the rights of third parties, particularly by not using, reproducing, copying, distributing, modifying, representing, or distributing texts, photographs, or any other element or works protected by copyright, trademark law, or any other intellectual property right belonging to third parties;</li>
                    <li>Not infiltrate a computer system, not alter content, or commit any offenses punished by articles 323-1 to 323-7 of the Penal Code ("hacking");</li>
                    <li>Not send unsolicited bulk messages ("spamming").</li>
                    <li>Not engage in activities that could cause social instability or threaten national security as outlined in Article 18.1 of the Cybersecurity Law.</li>
                </ul>
                <h3>4.2. MEMBER OBLIGATIONS</h3>
                <p>Members agree to comply with all these Terms and Conditions and applicable laws and regulations and, in particular, to:</p>
                <ul>
                    <li>Not use the site and/or its functionalities for illegal purposes including but not restricted to distributing illegal content, counterfeit goods, illegal products, drugs, pornographic materials, hazardous materials, weapons/ammunitions/explosives, restricted animal products, stolen goods, prescription drugs</li>
                    <li>Not engaging in self-promotion of their services, offers, or other activities, nor those of acquaintances.</li>
                    <li>Not impersonate any third party and/or use or attempt to use another member's profile;</li>
                    <li>Not allow others to use their profile and not transfer their profile to a third party;</li>
                    <li>Not circumvent a sanction by creating a new account on the site;</li>
                    <li>Not have multiple accounts on the site;</li>
                    <li>Publish accurate, reliable and clear information including but not restricted to title, description, link, image, comment</li>
                    <li>Not send a large number of similar comments;</li>
                    <li>Not send a large number of similar private messages;</li>
                    <li>Not send sponsorship proposals and no insinuation or hint of sponsorship in comments, deals, discussions, or unsolicited private messages;</li>
                    <li>Publish comments that adhere to high standards of courtesy, decency, and mutual respect;</li>
                    <li>Not distribute or publish documents or make statements likely to offend others, cause controversy (trolling), contrary to public order or morals, or infringe on the rights or dignity of third parties;</li>
                    <li>Not make defamatory, insulting, denigrating, racist, xenophobic, pornographic statements, or statements generally contrary to morality and decency and/or public order;</li>
                    <li>Not incite the commission of a crime, an offense, or an act of terrorism or make an apology for war crimes or crimes against humanity.</li>
                    <li>Ensure all posted content adheres to Vietnamese laws and regulations as required by Article 19.1 of Decree 53/2022/ND-CP.</li>
                </ul>
                <h3>4.3. OFFER CONDITIONS</h3>
                <p>To respect the community spirit of the site, members agree not to publish offers listed as excluded in the Deal Publishing Rules.</p>
                <h3>4.4. VOTING CONDITIONS FOR OFFERS</h3>
                <p>Members agree, when voting to promote an offer, to do so honestly to not alter the progress or decline of a relevant offer.</p>

                <h2>5. Links, Liability, Guarantee</h2>
                <h3>5.1. HYPERTEXT LINKS</h3>
                <p>The site contains hypertext links, generally those of offers added by members, pointing to resources or websites managed by third parties, which do not belong to or are not controlled by Dealio. The websites to which these hypertext links refer are, unless otherwise stated, independent of the Dealio site.</p>
                <p>Dealio exercises no control over the content or practices of third-party websites and disclaims any responsibility in this regard. Dealio cannot be held responsible for the content of these websites, and the fact that information about these sites appears on the site does not engage its responsibility.</p>
                <p>Users assume sole responsibility and all risks resulting from the consultation and use of these third-party websites. These links do not constitute, in any case, an endorsement or partnership between Dealio and these sites.</p>
                <p>Therefore, Dealio gives no guarantee regarding, in particular:</p>
                <ul>
                    <li>The truthfulness, timeliness, quality, completeness, and exhaustiveness of the content of the offers of products or services indexed on these websites;</li>
                    <li>The relevance and completeness of the indexed websites;</li>
                    <li>The difficulties of access and functioning of these websites.</li>
                </ul>
                <p>Consequently, Dealio cannot be held responsible in the event of a dispute of any nature arising between an indexed website on the site and a user. The operators of the websites referenced by Dealio are solely responsible for compliance with all regulations applicable to the products or services offered to users.</p>
                <p>Without Dealio's prior authorization, it is prohibited to provide on a third-party website a hypertext link pointing to the present site. If you wish to set up a hypertext link to the site, you must contact the site administrator using the contact form available in the "Contact" section on the home page.</p>
                <p>In no case should Dealio's web pages be embedded inside the pages of another site. Everything must be done to clearly indicate to the user that they are on the Dealio site and allow them to navigate freely. In any case, Dealio is not responsible for the links made to its site.</p>

                <h3>5.2. USER AND MEMBER LIABILITY AND GUARANTEE</h3>
                <p>Users are solely responsible for:</p>
                <ul>
                    <li>The use of the site and its services.</li>
                </ul>
                <p>Members are solely responsible for:</p>
                <ul>
                    <li>The offers and/or comments they publish or distribute on the site. In this regard, they guarantee that they have all the rights, licenses, consents, and authorizations necessary for the information they distribute and/or publish on the site. They guarantee that they respect the Vietnam’s Law on Cybersecurity in accordance with Article 19.1 of Decree 53/2022. Finally, they declare and guarantee that the information put online is not confidential and does not violate any contractual obligation.</li>
                </ul>
                <p>Users guarantee Dealio against any damage suffered by it and against any action in liability that would be brought against it based on the violation of these Terms and Conditions and/or any right of a third party. In the case of proven fraud, the site authorizes itself to communicate all necessary information to the competent bodies responsible for suppressing said frauds and offenses.</p>
                <p>Users particularly guarantee Dealio against any claim, legal action, or demand, including, but not limited to, (i) any action concerning the information put online by users, (ii) any use by users of the information on the site, (iii) or any violation of these Terms and Conditions.</p>

                <h2>6. Moderation and Sanctions</h2>
                <h3>6.1. MODERATION OF OFFERS AND SANCTIONS</h3>
                <p>Dealio reserves the discretionary right to moderate, i.e., modify, move, or delete offers and/or content published by members. In case of a member's breach of one of the offer conditions provided in section 4, the member can be warned, but not necessarily, be banned from accessing the site's services, especially in the case of repeated offences.</p>

                <h3>6.2. MODERATION OF COMMENTS AND SANCTIONS</h3>
                <p>Dealio may moderate, i.e., modify or delete, statements made by members and block access to comments and services for members who make statements likely to offend others or infringe on their rights, or who do not comply with these Terms and Conditions, particularly those in section 4 above. The member could receive a prior warning but not necessary.</p>
                <p>In case of a repeat offense but not restricted to it, the offending member may be banned from accessing the site's services. Dealio reserves the discretionary right to refuse certain hypertext links appearing in comments. This deletion right applies to all links on the site.</p>

                <h2>7. Profile Deletion</h2>
                <h3>7.1. PROFILE DELETION</h3>
                <h4>7.1.1. At the Initiative of Members</h4>
                <p>A member can request the deletion of their profile at any time by request to dealio.vn@gmail.com. The profile deletion is permanent, and the personal data linked to it cannot be recovered by members. However, offers, votes for offers, topics, and comments left by the member on the site will be retained and anonymized. The profile will be kept in memory by the site for the legally authorized duration.</p>

                <h4>7.1.2. At the Initiative of Dealio</h4>
                <p>In case of a member's breach of one of their obligations, particularly those in section 4 above, Dealio reserves the discretionary right to suspend or delete the member's account immediately, without notice or compensation. The profile deletion is permanent, and the personal data linked to it cannot be recovered by members. Dealio cannot be held responsible for the consequences of this deletion. However, offers, votes for offers, topics, and comments left by the member on the site will be retained and anonymized. The profile will be kept in memory by the site for the legally authorized duration.</p>

                <h2>8. Dealio's Liability, Reporting</h2>
                <h3>8.1. DEALIO'S LIABILITY</h3>
                <p>The site is merely a community space for sharing various promotional offers. Dealio only provides users with a platform of services, as well as tools and technical means for sharing offers. Dealio acts solely as a content host, providing simple technical storage and availability of spaces in accordance with Vietnamese law and regulations as outlined in the Cybersecurity Law and Decree 53/2022.</p>
                <p>Dealio conducts prior moderation to enhance the overall quality of content published by members. However, any issues not identified during moderation, including violations of the law or these Terms and Conditions, are the sole responsibility of the user. Dealio cannot be held responsible for any such content. Therefore, it cannot be held liable for any illegal or non-compliant content that may be published. Any formatting or layout of these automatically generated elements by the site does not imply Dealio's endorsement or involvement in their posting.</p>
                <p>The choice of offer classification categories, as well as the general organization and operation of the site, is only intended to facilitate its use and should not be interpreted as elements demonstrating the determination of any editorial policy or line. Only users decide on the posted offers. The site's algorithms then automate the classification of offers and their appearance on the site's front page.</p>
                <p>Finally, Dealio cannot be held liable to users for:</p>
                <ul>
                    <li>Any indirect damage they may suffer, including any loss of profit (whether direct or indirect), loss of clientele or business reputation, or any loss of data they may incur;</li>
                    <li>Any loss or damage they may suffer due to:</li>
                    <li>The trust placed in the completeness, accuracy, or existence of any advertising, information, or videos on the site or resulting from any relationship or transaction concluded between the user and any advertiser or partner whose advertising or works appear on the site;</li>
                    <li>Any change Dealio may make to the site in general, or any temporary or permanent deletion of an offer;</li>
                    <li>The deletion, damage, or failure to store the profile or any other communication data stored or transmitted;</li>
                    <li>Their relationship with other members with whom they interact;</li>
                    <li>Accessing or using, or the inability to use or access the site in general;</li>
                    <li>The inability to keep a password or information provided during registration secure and confidential;</li>
                    <li>The inability to delete, or the inability to store or transmit information, comments, or messages;</li>
                    <li>Any information and/or comment on the site;</li>
                    <li>Any behavior of a third party on the site in general, including but not limited to any defamatory, offensive, or illegal conduct of other members or any third party;</li>
                    <li>Any content inserted in messages sent through the site.</li>
                </ul>

                <h3>8.2. REPORTING OFFERS</h3>
                <p>The Dealio site allows its members to report offers and comments posted by other members if they believe that one of the rules of the site's Terms and Conditions, particularly those in section 4 above, has been violated. Reported offers and comments will be analyzed by administrators and/or moderators to determine if the reported comment or offer indeed violates the site's Terms and Conditions. Dealio reserves the right to block, modify, or delete reported comments and offers. Dealio reserves the right to restrict or block access to all or part of the services to members who abuse this reporting system.</p>

                <h2>9. Personal Data and Identifiers</h2>
                <h3>9.1. PERSONAL DATA</h3>
                <h4>9.1.1. Collection and Use of Personal Data</h4>
                <p>Dealio is committed to protecting the personal data of its users. Personal data refers to any information that can identify an individual, such as but not restricted as name, email address, phone number.</p>

                <h4>9.1.2. Data Collected</h4>
                <ul>
                    <li>Registration Data: When users create an account, Dealio collects information such as name, email address, and password.</li>
                    <li>Profile Data: Users may provide additional information for their profiles, including profile pictures and personal descriptions.</li>
                    <li>Usage Data: Dealio collects information on how users interact with the site, including pages viewed, links clicked, and offers posted.</li>
                    <li>Communication Data: This includes information collected when users communicate with Dealio, such as through customer service requests or feedback forms.</li>
                </ul>

                <h4>9.1.3. Purpose of Data Collection</h4>
                <p>Dealio collects and processes personal data for the following purposes:</p>
                <ul>
                    <li>Account Management: To create and manage user accounts.</li>
                    <li>Service Delivery: To provide and improve Dealio's services, including managing offers, votes, and comments.</li>
                    <li>Communication: To send administrative messages and respond to user inquiries.</li>
                    <li>Security: To protect the security of the site and prevent fraudulent activities.</li>
                    <li>Legal Compliance: To comply with legal obligations and respond to requests from government authorities.</li>
                </ul>

                <h4>9.1.4. Data Sharing</h4>
                <p>Dealio may share personal data with third parties in the following circumstances:</p>
                <ul>
                    <li>Service Providers: Dealio may share data with third-party service providers who perform services on its behalf, such as payment processing and data analysis.</li>
                    <li>Legal Requirements: Dealio may disclose personal data if required to do so by law or in response to a legitimate request from a law enforcement authority or government official.</li>
                    <li>Business Transfers: In the event of a merger, acquisition, or sale of all or part of Dealio's assets, personal data may be transferred to the acquiring entity.</li>
                </ul>

                <h4>9.1.5. Data Security</h4>
                <p>Dealio implements appropriate technical and organizational measures to protect personal data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access. However, no internet-based service can be completely secure, and Dealio cannot guarantee the absolute security of personal data. Dealio complies with Vietnamese regulations on personal data protection. Users' data will be processed in accordance with the laws of Vietnam, including data localization requirements as stipulated by Decree 53/2022.</p>

                <h4>9.1.6. Data Retention</h4>
                <p>Dealio retains personal data for as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations. Users can request the deletion of their data by contacting Dealio through the contact form.</p>

                <h4>9.1.7. User Rights</h4>
                <p>Users have the following rights regarding their personal data:</p>
                <ul>
                    <li>Access: Users can request access to their personal data held by Dealio.</li>
                    <li>Rectification: Users can request the correction of inaccurate or incomplete personal data.</li>
                    <li>Deletion: Users can request the deletion of their personal data.</li>
                    <li>Restriction: Users can request the restriction of processing of their personal data.</li>
                    <li>Portability: Users can request a copy of their personal data in a structured, commonly used, and machine-readable format.</li>
                    <li>Objection: Users can object to the processing of their personal data for certain purposes.</li>
                </ul>
                <p>To exercise these rights, users can contact Dealio through the contact form on the site.</p>

                <h4>9.1.8. Changes to this Policy</h4>
                <p>Dealio reserves the right to update this privacy policy at any time. Users will be notified of any significant changes through the site or by email.</p>

                <h2>10. Discussions</h2>
                <h3>10.1. DISCUSSIONS</h3>
                <p>These Terms and Conditions apply to the use and creation of discussion topics and their comments.</p>

                <h2>11. Nullity, Applicable Law, Competent Jurisdiction</h2>
                <h3>11.1. NULLITY</h3>
                <p>If one or more stipulations of these Terms and Conditions are held invalid or declared as such by a law, regulation, or following a final decision of a competent court, the other stipulations will retain their full force and scope.</p>

                <h3>11.2. APPLICABLE LAW, COMPETENT JURISDICTION</h3>
                <p>The use and operation of the site are subject to Vietnamese law and international law. The validity, performance, and interpretation of these Terms and Conditions applicable to the site are also subject to Vietnamese law and international law.</p>
                <p>TO THE EXTENT PERMITTED BY LOCAL LAW APPLICABLE TO THE USER, ANY DISPUTE ARISING FROM THE VALIDITY, INTERPRETATION, AND/OR PERFORMANCE OF THESE TERMS AND CONDITIONS, WHICH THE PARTIES CANNOT RESOLVE AMICABLY, WILL BE SUBMITTED TO THE EXCLUSIVE JURISDICTION OF THE VIETNAMESE COURTS, EVEN IN CASE OF MULTIPLE DEFENDANTS, EMERGENCY PROCEEDINGS, OR THIRD-PARTY NOTICE.</p>
            </div>
        </Container>
    );
}

export default TermsAndConditions;