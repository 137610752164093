//DescriptionBox.js

import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import './DescriptionBox.css';
import 'quill-emoji';

const DescriptionBox = ({ value, onChange }) => {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'bullet' }],
            ['link'],
            ['emoji'],  // Add emoji button to the toolbar
            ['clean']
        ],
        "emoji-toolbar": true,
    };

    return (
        <ReactQuill
            value={value}
            onChange={onChange}
            modules={modules}
            className="description-box__editor"
        />
    );
};

export default DescriptionBox;