import React, { createContext, useState, useEffect } from 'react';
import { API_URL } from '../services/api';
import { useTranslation } from 'react-i18next'; // Add this import
import apiService from '../services/api'; // Import the apiService

export const AuthContext = createContext();

export const AuthProvider = ({ children, alertPopup }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [userName, setUserName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation(); // Initialize the translation function

    useEffect(() => {
        // Load the auth state from localStorage first
        const localAuthStatus = localStorage.getItem('isAuthenticated');
        const localUserName = localStorage.getItem('userName');
        const localUserRole = localStorage.getItem('userRole');

        if (localAuthStatus && JSON.parse(localAuthStatus)) {
            setIsAuthenticated(true);
            setUserName(localUserName);
            setUserRole(localUserRole);
        }

        // Check the server for any updates to the authentication state
        checkAuthentication();
    }, []);

    const checkAuthentication = async () => {
        // First check if the server is reachable
        const isServerReachable = await apiService.checkServerStatus(alertPopup);

        if (!isServerReachable) {
            // If the server is unreachable, show server connection error
            return;
        }

        try {
            // Proceed with authentication check if server is reachable
            const response = await fetch(API_URL + 'api/username', { credentials: 'include' });
            const data = await response.json();
            
            if (data.isAuthenticated) {
                login({ name: data.userName, role: data.userRole });
            } else {
                // Only log out if the user is already authenticated
                if (isAuthenticated) {
                    logout();
                }
            }
        } catch (error) {
            // Show authentication error
            //alertPopup(t('auth_context_check_auth_status_error'), 'error');
            if (isAuthenticated) {
                logout();
            }
        }
    };

    const login = (userData) => {
        setIsAuthenticated(true);
        setUserRole(userData.role);
        setUserName(userData.name);

        // Store auth info in localStorage
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('userName', userData.name);
        localStorage.setItem('userRole', userData.role);
    };

    const logout = async () => {
        try {
            await fetch(API_URL + 'auth/logout', { credentials: 'include' });
            setIsAuthenticated(false);
            setUserRole(null);
            setUserName('');

            // Remove auth info from localStorage
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRole');

            // Refresh the page to reset all states
            window.location.reload(); // This will reload the page and reset the application state

        } catch (error) {
            alertPopup(t('auth_context_logout_error'), 'error');
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            userRole,
            userName,
            setUserName,
            isModalOpen,
            toggleModal,
            login,
            logout,
            checkAuthentication
        }}>
            {children}
        </AuthContext.Provider>
    );
};