// contexts/HomePageContext.js

import React, { createContext, useState } from 'react';

export const HomePageContext = createContext();

export const HomePageProvider = ({ children }) => {
    const [deals, setDeals] = useState([]);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    return (
        <HomePageContext.Provider value={{ deals, setDeals, skip, setSkip, hasMore, setHasMore, scrollPosition, setScrollPosition }}>
            {children}
        </HomePageContext.Provider>
    );
};