import React, { useState, useEffect, useRef } from 'react';
import apiService from '../services/api';
import Container from '../components/Container';
import { Link } from 'react-router-dom';
import './AdminReports.css'; 

export default function AdminDealsValidation() {
    const [deals, setDeals] = useState([]);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const limit = 4;
    const loadBeforeReachBottom = 2000;
    const alreadyFetched = useRef(new Set());

    useEffect(() => {
        fetchDeals();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - loadBeforeReachBottom && !loading && hasMore) {
                fetchDeals();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    const fetchDeals = async () => {
        if (loading || alreadyFetched.current.has(skip)) return;
        setLoading(true);
        alreadyFetched.current.add(skip);
        try {
            const fetchedDeals = await apiService.getAllDealsAdmin(limit, skip, null, null, true); // Pass the flag for pending validation
            setDeals(prevDeals => [...prevDeals, ...fetchedDeals]);
            setSkip(prevSkip => prevSkip + limit);
            setHasMore(fetchedDeals.length === limit);
        } catch (error) {
            console.error("Failed to fetch deals:", error.message);
            if (error.message === 'Forbidden access') {
                window.location.href = '/';
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (dealId) => {
        try {
            const result = await apiService.deleteDealClick(dealId);
            alert(`${result.message}: ${result.deletedDealId}`);
            setDeals(deals.filter(deal => deal._id !== dealId));
        } catch (error) {
            console.error("Failed to delete deal:", error);
        }
    };

    const createSlug = (title) => {
        if (!title) {
            return '';
        }
        return title
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .toLowerCase();
    };

    const setDealStatus = async (dealId, status) => {
        try {
            const result = await apiService.updateDealStatus(dealId, status);
            alert(result.message);
        } catch (error) {
            console.error(`Failed to update deal status to ${status}:`, error);
        }
    };

    return (
        <div className="admin">
            <Container>
                <div className="admin__boxes">
                    <section className="admin__intro-section">
                        <h1>Pending Validation Deals</h1>
                        <Link to="/admin/reports">Go to Admin Reports</Link>
                    </section>
    
                    <section className="admin__display-deals-section">
                        {deals.map(deal => (
                            <div key={deal._id} className="admin__deal-box">
                                <div className="admin__deal-box-description">
                                    <Link to={`/deal/${createSlug(deal.title)}/${deal._id}`}>
                                        <b>ID:</b> {deal._id || 'Empty'}
                                    </Link>
                                    <br />
                                    <b>Title:</b> {deal.title || 'Empty'}
                                    <br />
                                    <b>Link:</b> {deal.link || 'Empty'}
                                    <br />
                                    <b>Discounted Price:</b> {deal.discounted_price || 'Empty'} &rarr; <b>Regular Price:</b> {deal.regular_price || 'Empty'}
                                    <br />
                                    <b>Category:</b> {deal.category || 'Empty'}
                                    <br />
                                    <b>Location:</b> {deal.location || 'Empty'}
                                    <br />
                                    <b>Promo Code:</b> {deal.promo_code || 'Empty'}
                                    <br />
                                    <b>Shop:</b> {deal.shop || 'Empty'}
                                    <br />
                                    <b>User ID:</b> {deal.user_id || 'Empty'}
                                    <br />
                                    <b>Start Date:</b> {deal.start_date || 'Empty'} - <b>End Date:</b> {deal.end_date || 'Empty'}
                                    <br />
                                    <b>Status:</b> {deal.status || 'Empty'}
                                    <br />
                                    <b>Description:</b> <div dangerouslySetInnerHTML={{ __html: deal.description || 'Empty' }}></div>
                                    <br />
                                    <b>Date Registering:</b> {deal.date_registering || 'Empty'}
                                    <br />
                                    {deal.image1_compressed && <img src={deal.image1_compressed} alt="Deal Image 1 compressed" className="admin__deal-box-image" />}
                                    {deal.image2 && <img src={deal.image2} alt="Deal Image 2" className="admin__deal-box-image" />}
                                    {deal.image3 && <img src={deal.image3} alt="Deal Image 3" className="admin__deal-box-image" />}
                                    <br />
                                    <div className="admin__deal-box-controls">
                                        <button onClick={() => handleDelete(deal._id)}>Delete Deal</button>
                                        <button onClick={() => setDealStatus(deal._id, 'Expired')}>Expired</button>
                                        <button onClick={() => setDealStatus(deal._id, 'Cancelled')}>Cancelled</button>
                                        <button onClick={() => setDealStatus(deal._id, 'Valid')}>Valid</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </section>
                </div>
            </Container>
        </div>
    );
}