// utils/userActions.js

import { shareDeal } from '../components/ShareDeal'; // Adjust path based on your project structure
import { t } from 'i18next';

// Utility function to log events to Google Analytics
export const logGoogleEvent = (eventName, eventCategory, eventLabel, eventValue) => {
    if (window.gtag) {
        window.gtag('event', eventName, {
            event_category: eventCategory,
            event_label: eventLabel,
            value: eventValue,
        });
    } else {
        console.warn('Google Analytics is not initialized');
    }
};

// Handle voting action (common between HomePage and DealPage)
export const handleVote = async (dealId, value, setDeals, toggleModal, apiService, isAuthenticated, deals, voteUpButtonRefs, voteDownButtonRefs, alertPopup) => {
    // Check if the user is authenticated
    //console.log('isAuthenticated:', isAuthenticated);
    if (!isAuthenticated) {
        //console.log('User is not authenticated, opening login modal');
        toggleModal();
        return;
    }

    // Find the deal using dealId
    const dealIndex = deals.findIndex(d => d._id === dealId);

    if (dealIndex === -1) {
        //console.log('Deal not found');
        return;
    }

    const deal = deals[dealIndex];
    //console.log('Selected deal:', deal);

    // Check if the user has already voted to avoid double voting
    if (deal.user_vote !== 0) {
        // Add shake animation to indicate the user can't vote again
        const buttonRef = value === 1 ? voteUpButtonRefs.current[dealIndex] : voteDownButtonRefs.current[dealIndex];
        buttonRef.current.classList.add('shake');
        setTimeout(() => {
            buttonRef.current.classList.remove('shake');
        }, 500);
        return;
    }

    try {
        // Optimistically update the UI before sending the request
        const previousVote = deal.user_vote;
        const updatedVote = value;
        const voteDifference = updatedVote - previousVote;

        const updatedDeals = [...deals];
        updatedDeals[dealIndex] = {
            ...deal,
            vote: deal.vote + voteDifference,
            user_vote: updatedVote
        };
        setDeals(updatedDeals);

        // Send the vote to the API
        const responseData = await apiService.voteDeal(dealId, value, alertPopup);
        //console.log("Vote API response:", responseData);

        // Log the vote action to Google Analytics only after successful registration
        logGoogleEvent('deal interaction', 'Vote on deal', dealId, value);

        // Update the deals with the response from the server
        const finalDeals = [...updatedDeals];
        finalDeals[dealIndex] = {
            ...deal,
            vote: responseData.vote, // Update with actual vote count from the server
            user_vote: responseData.user_vote // Update the user's vote
        };
        setDeals(finalDeals);
    } catch (error) {
        alertPopup(t('user_action_failed_vote'), 'error');

        // Revert to the previous state if the request fails
        const revertedDeals = [...deals];
        revertedDeals[dealIndex] = {
            ...deal,
            vote: deal.vote,
            user_vote: deal.user_vote
        };
        setDeals(revertedDeals);
    }
};

// Handle sharing a deal (common between HomePage and DealPage)
export const handleShareDeal = (deal, alertPopup, t) => {
    // Log the share action to Google Analytics
    logGoogleEvent('deal interaction', 'Share deal', deal.title, deal._id);

    // Call the actual shareDeal logic (existing functionality)
    shareDeal(deal.title, deal._id, alertPopup, t);
};

// Handle external link clicks (common between HomePage and DealPage)
export const handleExternalLinkClick = (deal) => {
    // Log the click action to Google Analytics
    logGoogleEvent('deal interaction', 'Click on external link', deal.title, deal._id);

    // Open the external deal link
    window.open(deal.link, '_blank');
};