import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './MenuModal.css';
import { AuthContext } from '../contexts/AuthContext';

import LangENIcon from '../assets/global-icons/langage_en.svg'; // Import English SVG icon
import LangVIVIcon from '../assets/global-icons/langage_vn.svg'; // Import Vietnamese SVG icon
import LoginIcon from '../assets/login_icon.svg'; // Import the same sign-in icon from the header
import LogoutIcon from '../assets/global-icons/logout.svg'; // Import the logout icon

function MenuModal({ onClose }) {
    const { t, i18n } = useTranslation();
    const { isAuthenticated, toggleModal, logout } = useContext(AuthContext);
    const modalRef = useRef(null);

    const handleLogout = () => {
        logout();
        onClose();
    };

    const switchLanguage = (lang) => {
        i18n.changeLanguage(lang); // Switch language using i18n
        onClose(); // Close modal after language switch
    };

    useEffect(() => {
        // Add class to body to prevent scrolling
        document.body.classList.add('modal-open');

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.body.classList.remove('modal-open');
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <>
            <div className="account-modal-overlay"></div>
            <div
                className="account-modal"
                ref={modalRef}
            >
                <div className="account-modal__arrow"></div>
                <div className="account-modal__content">
                    {/* Language selection with SVG icons */}
                    <div className="account-modal__header">
                        <div className="account-modal__lang-container">
                            <button
                                className="account-modal__lang-box"
                                onClick={() => switchLanguage('vi')}
                            >
                                <img src={LangVIVIcon} alt="Vietnamese" className="account-modal__lang-icon" />
                                <span>Việt</span>
                            </button>
                        </div>
                        <div className="account-modal__lang-container">
                            <button
                                className="account-modal__lang-box"
                                onClick={() => switchLanguage('en')}
                            >
                                <img src={LangENIcon} alt="English" className="account-modal__lang-icon" />
                                <span>Eng</span>
                            </button>
                        </div>
                    </div>
                    <div className="account-modal__options">
                        {!isAuthenticated && (
                            <button
                                onClick={() => {
                                    toggleModal();
                                    onClose();
                                }}
                                className="account-modal__option"
                            >
                                <img src={LoginIcon} alt="Login" className="account-modal__icon" />
                                {t('account_modal_connect')}
                            </button>
                        )}
                        <a href="/terms-and-conditions" target="_blank" className="account-modal__option">
                            {t('account_modal_terms_conditions')}
                        </a>
                        {isAuthenticated && (
                            <button onClick={handleLogout} className="account-modal__option">
                                <img src={LogoutIcon} alt="Logout" className="account-modal__icon" />
                                {t('account_modal_disconnect')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MenuModal;
