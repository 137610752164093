// ShareDeal.js

import { FRONT_URL } from '../services/api';
import { createSlug } from '../utils/utils';

export const shareDeal = async (dealTitle, dealId, alertPopup, t) => {
    const formattedTitle = createSlug(dealTitle);
    const dealUrl = `${FRONT_URL}deal/${formattedTitle}/${dealId}`;

    const shareData = {
        title: t('share_deal_title', { dealTitle }),
        text: t('share_deal_text', { dealTitle }),
        url: dealUrl,
    };

    try {
        if (navigator.share) {
            await navigator.share(shareData);
        } else {
            const fullMessage = `${shareData.title}\n${shareData.text}\n${shareData.url}`;
            await navigator.clipboard.writeText(fullMessage);
            alertPopup(t('share_deal_alert_link_copied'), 'success');
        }
    } catch (error) {
        if (error.name === 'AbortError' || error.message === 'Share canceled') {
            //console.log('Share canceled by user.');
        } else {
            alertPopup(t('share_deal_alert_error_sharing'), 'error');
        }
    }
};