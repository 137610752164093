//App.js

//To launch: command prompt -> npm start
// To launch onb local network: command prompt -> set HOST=0.0.0.0&&npm start
// Powershell -> $env:HOST="0.0.0.0"; npm start

/*
Command to push in github
git add .
git commit -m "{Name of modification}"
git push origin main
*/

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import AlertPopup from './components/AlertPopup'; // Ensure this path matches the location of your AlertPopup component

//For languages
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n'; // Assuming you put i18n.js inside utils

// Import pages and components
import HomePage from './views/HomePage';
import NewDeal from './views/NewDeal';
import AdminReports from './views/AdminReports';
import AdminValidation from './views/AdminDealsValidation';
import DealPage from './views/DealPage';
import Header from './components/Header';
import NotFoundPage from './components/NotFoundPage';
import TermsAndConditions from './views/TermsAndConditions'; 

// Import global styles
import './assets/global.css';
import './assets/fonts.css';

function App() {

  /***** Alert component on the whole app *****/
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: '', // Can be 'informative', 'success', or 'error'
    isVisible: false,
  });

  // Function to show the alert
  const alertPopup = (message, type) => {
    setAlertInfo({
      message,
      type,
      isVisible: true,
    });

    // Hide the alert after 5 seconds
    setTimeout(() => {
      setAlertInfo(prevState => ({ ...prevState, isVisible: false }));
    }, 5000);
  };
    /*____________________*/

  return (
    <I18nextProvider i18n={i18n}>  {/* Language wrap */}
      <AuthProvider alertPopup={alertPopup}>
        <Router>
          <div className="App">
            {/* Global Header */}
            <Header />

            {alertInfo.isVisible && (
              <AlertPopup
                message={alertInfo.message}
                type={alertInfo.type}
                onClose={() => setAlertInfo(prevState => ({ ...prevState, isVisible: false }))}
              />
            )}

            {/* Routing setup */}
            <Routes>
              <Route path="/" element={<HomePage alertPopup={alertPopup} />} />
              <Route path="/category" element={<HomePage alertPopup={alertPopup} />} />
              <Route path="/search" element={<HomePage alertPopup={alertPopup} />} />
              <Route path="/newdeal" element={<NewDeal alertPopup={alertPopup} />} />
              <Route path="/admin/reports" element={<AdminReports alertPopup={alertPopup} />} />
              <Route path="/admin/deal_validation" element={<AdminValidation alertPopup={alertPopup} />} />
              <Route path="/deal/:title/:id" element={<DealPage alertPopup={alertPopup} />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              {/* Fallback for 404 Not Found */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>

            {/* Global Footer */}
            {/* <Footer /> */}
          </div>
        </Router>
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;