import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import apiService from '../services/api';
import Container from '../components/Container';
import './NewDeal.css';
import ImageDropZone from '../components/ImageDropZone';
import DescriptionBox from '../components/DescriptionBox';
import LoadingSpinner from '../components/LoadingSpinner';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { PROVINCES } from '../constants/ProvinceList.js';
import { CATEGORIES } from '../constants/CategoriesHeaderList';

import linkIcon from '../assets/global-icons/link-color.svg';
import titleIcon from '../assets/global-icons/title-color.svg';
import discountedPriceIcon from '../assets/global-icons/current-price-color.svg';
import regularPriceIcon from '../assets/global-icons/regular-price-color.svg';
import promoCodeIcon from '../assets/global-icons/promo-code-color.svg';
import shopIcon from '../assets/global-icons/shop-color.svg';
import locationIcon from '../assets/global-icons/location-color.svg';
import startDateIcon from '../assets/global-icons/start-date.svg';
import endDateIcon from '../assets/global-icons/end-date.svg';

export default function NewDeal({ alertPopup }) {
    const { t } = useTranslation(); // Use useTranslation hook for translations
    const { isAuthenticated, authLoading, toggleModal } = useContext(AuthContext);
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const [modalShown, setModalShown] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const MAX_PRICE = 9999999999;

    const [selectedCategoryKey, setSelectedCategoryKey] = useState('');  // Store the category key (translationKey) for display and submission
    const [categories] = useState(CATEGORIES); // Use the static CATEGORIES array
    const [focusedField, setFocusedField] = useState('');
    const [uploadedFileNames, setUploadedFileNames] = useState([]);
    const [images, setImages] = useState([null, null, null]);
    const [isNationalDeal, setIsNationalDeal] = useState(true);
    const [locationDropdown, setLocationDropdown] = useState('');
    const [noLinkAvailable, setNoLinkAvailable] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [urlLengthError, setUrlLengthError] = useState(false);
    const [titleLengthError, setTitleLengthError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [promoCodeError, setPromoCodeError] = useState(false);
    const [attemptedNextWithoutImage, setAttemptedNextWithoutImage] = useState(false);
    const [descriptionLengthError, setDescriptionLengthError] = useState(false);
    const todayDate = new Date().toISOString().split('T')[0];
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0];
    const [categoryError, setCategoryError] = useState(false);
    const [priceComparisonError, setPriceComparisonError] = useState(false);
    const [dateComparisonError, setDateComparisonError] = useState(false);
    const [CurrentPriceError, setCurrentPriceError] = useState(false);
    const [RegularPriceError, setRegularPriceError] = useState(false);
    const [discountedPriceErrorClass, setCurrentPriceErrorClass] = useState('');
    const [regularPriceErrorClass, setRegularPriceErrorClass] = useState('');
    const [shopError, setShopError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const timeoutRef = useRef(null);
    const [dealData, setDealData] = useState({
        title: '',
        link: '',
        discounted_price: '',
        regular_price: '',
        promo_code: '',
        location: 'National',
        shop: '',
        description: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        image1: null,
        image2: null,
        image3: null
    });

    // Check if the user is authenticated
    useEffect(() => {
        const localAuthStatus = localStorage.getItem('isAuthenticated');
        const isUserAuthenticated = localAuthStatus && JSON.parse(localAuthStatus);

        // Show modal only if not authenticated locally and modal not already shown
        if (!isUserAuthenticated && !modalShown) {
            toggleModal();
            setModalShown(true); // Ensure modal is shown only once
        }
    }, [modalShown, toggleModal]);

    useEffect(() => {
        setIsAnimating(true); // Trigger animation on mount
    }, [currentStep]);

    // Function to handle field focus
    const handleFieldFocus = (fieldName) => {
        setFocusedField(fieldName);
    };

    // Function to handle field blur
    const handleFieldBlur = () => {
        setFocusedField('');
    };

    const handleChange = (e) => {
        let { name, value } = e.target;

        // Handle URL changes
        if (name === "link") {
            // Check if URL length exceeds 2000 characters
            if (value.length > 2000) {
                setUrlLengthError(true);
            } else {
                setUrlLengthError(false);
            }

            // Validate the URL format
            const isValidUrl = isUrlValid(value) || value === '';
            setUrlError(!isValidUrl);
        }

        // Check title length (must be between 4 and 140 characters)
        if (name === "title") {
            const isTitleLengthValid = value.trim().length >= 4 && value.trim().length <= 140;
            setTitleLengthError(!isTitleLengthValid);
        }

        // Validate discounted_price and regular_price (must be non-negative numbers) and < MAX_PRICE
        if (name === "discounted_price" || name === "regular_price") {
            if (parseFloat(value) < 0 || isNaN(parseFloat(value)) || parseFloat(value) > MAX_PRICE) {
                if (name === "discounted_price") {
                    setCurrentPriceError(true);
                    clearErrorsAfterDelay(() => setCurrentPriceError(false), setCurrentPriceErrorClass);
                } else {
                    setRegularPriceError(true);
                    clearErrorsAfterDelay(() => setRegularPriceError(false), setRegularPriceErrorClass);
                }
                if (parseFloat(value) < 0 || isNaN(parseFloat(value))) {
                    value = '';
                    e.target.value = ''; // Update the input field value to ''
                }
            } else {
                if (name === "discounted_price") {
                    setCurrentPriceError(false);
                } else {
                    setRegularPriceError(false);
                }
            }
            value = value === '' ? '' : parseFloat(value).toString();
        }

        // Compare discounted_price and regular_price (discounted_price must be <= regular_price)
        if (name === "discounted_price" || name === "regular_price") {
            const discountedPriceValue = name === "discounted_price" ? value : dealData.discounted_price;
            const regularPriceValue = name === "regular_price" ? value : dealData.regular_price;
            const isPriceComparisonValid = !discountedPriceValue || !regularPriceValue || parseFloat(discountedPriceValue) < parseFloat(regularPriceValue);

            setPriceComparisonError(!isPriceComparisonValid);
        }

        // Check shop name length (must be <= 60 characters)
        if (name === "shop") {
            if (value.length > 60) {
                setShopError(true);
            } else {
                setShopError(false);
            }
        }

        // Check promo code length (must be <= 140 characters)
        if (name === "promo_code") {
            if (value && value.length > 140) {
                setPromoCodeError(true);
            } else {
                setPromoCodeError(false);
            }
        }

        const updatedDealData = {
            ...dealData,
            [name]: value
        };

        // Validate date comparisons (start_date must be <= end_date)
        if (name === "start_date" || name === "end_date" || name === "start_time" || name === "end_time") {
            const startDateTime = `${updatedDealData.start_date}T${updatedDealData.start_time || '00:00'}`;
            const endDateTime = `${updatedDealData.end_date}T${updatedDealData.end_time || '23:59'}`;
            const start = new Date(startDateTime);
            const end = new Date(endDateTime);
            const isValidDateComparison = !updatedDealData.end_date || start <= end;
            setDateComparisonError(!isValidDateComparison);
        }

        setDealData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Prevent form submission on Enter Key - redirect to wrong url - and send to next step
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            //handleNextStep();
        }
    };

    // Handle category selection
    const handleDealCategorySelection = (category) => {
        setSelectedCategoryKey(category.translationKey);  // Store the category key
        setCategoryError(false);
    };

    // **Handle browser history for step transitions to ensure browser back button send to previous step and not previous page**:
    useEffect(() => {
        // Replace initial state in history so the current step is reflected properly
        window.history.replaceState({ step: currentStep }, '');
    
        const handlePopState = (event) => {
            if (event.state && event.state.step) {
                const previousStep = event.state.step;
    
                // If the previous step is valid and within range
                if (previousStep < currentStep && previousStep >= 1) {
                    setCurrentStep(previousStep);
                }
            }
        };
    
        // Add popstate listener
        window.addEventListener('popstate', handlePopState);
    
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [currentStep]);

    const handleNextStep = () => {
        if (!isAuthenticated) {
            toggleModal();
            return;
        }

        setIsAnimating(false); // Reset animation state before changing step

        setTimeout(() => {
            if (currentStep === 1) {
                const isLinkValid = noLinkAvailable || (dealData.link.length <= 2000 && isUrlValid(dealData.link));

                setUrlError(!isLinkValid && !noLinkAvailable);
                setUrlLengthError(dealData.link.length > 2000);

                if (!isLinkValid) {
                    return;
                }
            } else if (currentStep === 2) {
                const isTitleLengthValid = dealData.title?.trim().length >= 4 && dealData.title?.trim().length <= 140;
                setTitleLengthError(!isTitleLengthValid);
            
                const isPromoCodeValid = !dealData.promo_code || dealData.promo_code.length <= 140;
                setPromoCodeError(!isPromoCodeValid);
            
                const isLocationValid = isNationalDeal || (locationDropdown !== '');
                setLocationError(!isLocationValid);
            
                const isCurrentPriceValid = dealData.discounted_price === '' || (!isNaN(parseFloat(dealData.discounted_price)) && parseFloat(dealData.discounted_price) >= 0 && parseFloat(dealData.discounted_price) <= MAX_PRICE);
                setCurrentPriceError(!isCurrentPriceValid);
    
                const isRegularPriceValid = dealData.regular_price === '' || (!isNaN(parseFloat(dealData.regular_price)) && parseFloat(dealData.regular_price) >= 0 && parseFloat(dealData.regular_price) <= MAX_PRICE);
                setRegularPriceError(!isRegularPriceValid);
    
                const isPriceComparisonValid = (dealData.discounted_price === '' || dealData.regular_price === '') || parseFloat(dealData.discounted_price) <= parseFloat(dealData.regular_price);
                setPriceComparisonError(!isPriceComparisonValid);
            
                if (!isTitleLengthValid || !isPromoCodeValid || !isLocationValid || !isCurrentPriceValid || !isRegularPriceValid || !isPriceComparisonValid) {
                    return;
                }
            } else if (currentStep === 3) {
                if (images[0] === null) {
                    setAttemptedNextWithoutImage(true);
                    return;
                }
            } else if (currentStep === 4) {
                if (descriptionLengthError) {
                    return;
                }
            } else if (currentStep === 5) {
                if (dateComparisonError) {
                    return;
                }

                if (!selectedCategoryKey) {
                    setCategoryError(true);
                    return;
                }
            }

            // Increment the step and push to history
            const newStep = currentStep + 1;
            setCurrentStep(newStep);
    
            // Push new state only when moving forward
            window.history.pushState({ step: newStep }, '');
            setIsAnimating(true); // Trigger animation for the new step
        }, 100);
    };

    const handleBackStep = () => {
        setIsAnimating(false); // Reset animation before changing step
    
        setTimeout(() => {
            const newStep = currentStep - 1;
            setCurrentStep(newStep);
    
            // Replace the state when moving back instead of pushing a new one
            window.history.replaceState({ step: newStep }, '');
            setIsAnimating(true); // Trigger animation for the new step
        }, 100);
    };

    const handleAddDealClick = async () => {
        setIsLoading(true);
        const form = document.getElementById("dealForm");
        if (!form.checkValidity()) {
            form.reportValidity();
            setIsLoading(false);
            return;
        }
    
        const fullStartDate = dealData.start_time ? `${dealData.start_date}T${dealData.start_time}` : dealData.start_date;
        const fullEndDate = dealData.end_time ? `${dealData.end_date}T${dealData.end_time}` : dealData.end_date;
    
        // Translate to English using `lng: 'en'`
        const englishCategoryName = t(selectedCategoryKey, { lng: 'en' });
    
        const submittedDealData = {
            ...dealData,
            start_date: fullStartDate,
            end_date: fullEndDate,
            category: englishCategoryName,  // Send the English name to the server
            image1: images[0],
            image2: images[1],
            image3: images[2]
        };
    
        // Debug to ensure correct data
        //console.log("Submitted deal data:", submittedDealData);
    
        try {
            const result = await apiService.addDealClick(submittedDealData, alertPopup);
            setIsLoading(false);
    
            if (result) {
                alertPopup(t('deal_page_validation_message'), 'success');
                navigate(`/`);
            } else {
                // No redirect; error message already displayed by `alertPopup` in `addDealClick`
            }
        } catch (error) {
            setIsLoading(false);
            alertPopup(t('deal_page_add_error'), 'error');
            console.error(error);
        }
    };

    const handleImageDrop = (file, index) => {
        setImages(prevImages => {
            let updatedImages = [...prevImages];

            if (file) {
                updatedImages[index] = file;
                if (!uploadedFileNames.includes(file.name)) {
                    setUploadedFileNames(prevNames => [...prevNames, file.name]);
                }
            } else {
                const existingFileName = updatedImages[index]?.name;
                updatedImages[index] = null;
                if (existingFileName) {
                    setUploadedFileNames(prevNames => prevNames.filter(name => name !== existingFileName));
                }
            }

            updatedImages = updatedImages.filter(image => image != null);
            while (updatedImages.length < 3) {
                updatedImages.push(null);
            }

            return updatedImages;
        });
    };

    const handleNationalDealChange = (e) => {
        setIsNationalDeal(e.target.checked);
        if (e.target.checked) {
            setDealData(prevData => ({
                ...prevData,
                location: "National"
            }));
            setLocationDropdown('');
            setLocationError(false);
        } else {
            setDealData(prevData => ({
                ...prevData,
                location: locationDropdown
            }));
        }
    };

    const handleLocationDropdownChange = (e) => {
        const selectedLocation = e.target.value;
        setLocationDropdown(selectedLocation);
        setDealData(prevData => ({
            ...prevData,
            location: selectedLocation
        }));

        if (selectedLocation !== "") {
            setLocationError(false);
        }
    };

    const handleNoLinkCheckboxChange = (e) => {
        setNoLinkAvailable(e.target.checked);
        if (e.target.checked) {
            setDealData(prevData => ({ ...prevData, link: '' }));
            setUrlError(false);
            setUrlLengthError(false);
        }
    };

    const isUrlValid = (url) => {
        const urlRegex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/;
        return urlRegex.test(url);
    };

    const handleDescriptionChange = (content, delta, source, editor) => {
        //const textLength = editor.getLength();
        const textLength = dealData.description.length;

        if (textLength > 64000) {
            setDescriptionLengthError(true);
        } else {
            setDescriptionLengthError(false);
        }

        setDealData(prevData => ({
            ...prevData,
            description: content
        }));
    };

    const clearErrorsAfterDelay = (clearFunction, setErrorClassFunction) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setErrorClassFunction('fade-out');
            setTimeout(() => {
                clearFunction();
                setErrorClassFunction(''); // Reset the class after fade out
            }, 1000); // Wait for the fade-out transition to complete
        }, 3000);
    };

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 3:
                return renderStep3();
            case 4:
                return renderStep4();
            case 5:
                return renderStep5();
            case 6:
                return renderStep6();

            default:
                return <div>Unknown step</div>;
        }
    };

    const renderStep1 = () => {
        const inputClassName = (urlError || urlLengthError) ? 'new-deal__input--error' : '';
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">{t('new_deal_step1_title')}</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>{t('new_deal_step1_description')}</p><br />
                        <div className={`new-deal__field-container ${noLinkAvailable ? 'new-deal__field-container--disabled' : ''}`}>
                            <div className="new-deal__input-container">
                                <img src={linkIcon} className="new-deal__icon" alt="Link Icon" />
                                <div className="new-deal__sub-input-container">
                                    <span className="new-deal__sub-title">{t('new_deal_link')}</span> {/* Translated Link Label */}
                                    <input
                                        name="link"
                                        value={dealData.link}
                                        onChange={handleChange}
                                        className={inputClassName}
                                        autoComplete="off"
                                        placeholder={t('new_deal_link_placeholder')}
                                        disabled={noLinkAvailable}
                                        autoCapitalize="off"
                                    />
                                    {urlLengthError && (
                                        <div className="new-deal__error-message-box">
                                            {t('new_deal_url_too_long')}
                                        </div>
                                    )}
                                    <div className="new-deal__national-deal-checkbox new-deal__national-deal-checkbox--render1">
                                        <label htmlFor="noLinkCheckbox"><center>{t('new_deal_no_link_available')}</center></label> {/* Translated Checkbox */}
                                        <input
                                            type="checkbox"
                                            id="noLinkCheckbox"
                                            checked={noLinkAvailable}
                                            onChange={handleNoLinkCheckboxChange}
                                            className="new-deal__national-deal-input"
                                        />
                                    </div>
                                    {urlError && (
                                        <div className="new-deal__error-message-box">
                                            {t('new_deal_invalid_url')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleNextStep}>{t('new_deal_next')}</button>
                </div>
            </>
        );
    };
    
    const renderStep2 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">{t('new_deal_step2_title')}</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={titleIcon} className="new-deal__icon" alt="Title Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div>
                                        <span className="new-deal__sub-title">{t('new_deal_title')}</span> ({t('new_deal_mandatory')})<br />
                                        <input
                                            name="title"
                                            value={dealData.title}
                                            onChange={handleChange}
                                            className={titleLengthError ? 'new-deal__input--error' : ''}
                                            onFocus={() => handleFieldFocus('title')}
                                            onBlur={handleFieldBlur}
                                            required
                                            autoComplete="off"
                                            placeholder={t('new_deal_title_placeholder')}
                                        />
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'title' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'title' && (
                                            <p>
                                                <strong>{t('new_deal_title_info_strong')}</strong><br />
                                                {t('new_deal_title_info')}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {titleLengthError && (
                                <div className="new-deal__error-message-box">
                                    {t('new_deal_title_error')}
                                </div>
                            )}
                        </div>
                        <div className="new-deal__sub-field-container">
                            <div className="new-deal__field-container">
                                <div className="new-deal__input-container">
                                    <img src={discountedPriceIcon} className="new-deal__icon" alt="Current Price Icon" />
                                    <div className="new-deal__sub-input-container">
                                        <div>
                                            <label htmlFor="discounted_price"><span className="new-deal__sub-title">{t('new_deal_current_price')}</span></label><br />
                                            <input
                                                id="discounted_price"
                                                name="discounted_price"
                                                value={dealData.discounted_price}
                                                onChange={handleChange}
                                                onInput={handleChange}
                                                className={(priceComparisonError || CurrentPriceError) ? 'new-deal__input--error' : ''}
                                                onFocus={() => handleFieldFocus('discounted_price')}
                                                onBlur={handleFieldBlur}
                                                autoComplete="off"
                                                placeholder={t('new_deal_current_price_placeholder')}
                                            />
                                        </div>
                                        <div className={`new-deal__info-box ${focusedField === 'discounted_price' ? 'new-deal__info-box--visible' : ''}`}>
                                            {focusedField === 'discounted_price' && (
                                                <p><strong>{t('new_deal_current_price_info_strong')}</strong><br />
                                                    {t('new_deal_current_price_info')}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {priceComparisonError && (
                                    <div className="new-deal__error-message-box">
                                        {t('new_deal_current_price_comparison_error')}
                                    </div>
                                )}
                                {CurrentPriceError && (
                                    <div className={`new-deal__error-message-box ${discountedPriceErrorClass}`}>
                                        {t('new_deal_current_price_error')}
                                    </div>
                                )}
                            </div>
                            <div className="new-deal__field-container">
                                <div className="new-deal__input-container">
                                    <img src={regularPriceIcon} className="new-deal__icon" alt="Regular Price Icon" />
                                    <div className="new-deal__sub-input-container">
                                        <div>
                                            <label htmlFor="regular_price"><span className="new-deal__sub-title">{t('new_deal_regular_price')}</span></label><br />
                                            <input
                                                id="regular_price"
                                                name="regular_price"
                                                value={dealData.regular_price}
                                                onChange={handleChange}
                                                onInput={handleChange}
                                                className={RegularPriceError ? 'new-deal__input--error' : ''}
                                                onFocus={() => handleFieldFocus('regular_price')}
                                                onBlur={handleFieldBlur}
                                                autoComplete="off"
                                                placeholder={t('new_deal_regular_price_placeholder')}
                                            />
                                        </div>
                                        <div className={`new-deal__info-box ${focusedField === 'regular_price' ? 'new-deal__info-box--visible' : ''}`}>
                                            {focusedField === 'regular_price' && (
                                                <p>
                                                    <strong>{t('new_deal_regular_price_info_strong')}</strong><br />
                                                    {t('new_deal_regular_price_info')}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {RegularPriceError && (
                                    <div className={`new-deal__error-message-box ${regularPriceErrorClass}`}>
                                        {t('new_deal_regular_price_error')}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={promoCodeIcon} className="new-deal__icon" alt="Promo Code Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div>
                                        <label htmlFor="promo_code"><span className="new_deal__sub-title">{t('new_deal_promo_code')}</span></label><br />
                                        <input
                                            id="promo_code"
                                            type="text"
                                            name="promo_code"
                                            value={dealData.promo_code}
                                            onChange={handleChange}
                                            className={promoCodeError ? 'new-deal__input--error' : ''}
                                            onFocus={() => handleFieldFocus('promo_code')}
                                            onBlur={handleFieldBlur}
                                            autoComplete="off"
                                            placeholder={t('new_deal_promo_code_placeholder')}
                                        />
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'promo_code' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'promo_code' && (
                                            <p>{t('new_deal_promo_code_info')}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {promoCodeError && (
                                <div className="new-deal__error-message-box">
                                    {t('new_deal_promo_code_error')}
                                </div>
                            )}
                        </div>
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={shopIcon} className="new-deal__icon" alt="Shop Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div>
                                        <label htmlFor="shop"><span className="new-deal__sub-title">{t('new_deal_shop')}</span></label><br />
                                        <input
                                            id="shop"
                                            type="text"
                                            name="shop"
                                            value={dealData.shop}
                                            onChange={handleChange}
                                            className={shopError ? 'new-deal__input--error' : ''}
                                            onFocus={() => handleFieldFocus('shop')}
                                            onBlur={handleFieldBlur}
                                            autoComplete="off"
                                            placeholder={t('new_deal_shop_placeholder')}
                                        />
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'shop' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'shop' && (
                                            <p>{t('new_deal_shop_info')}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {shopError && (
                                <div className="new-deal__error-message-box">
                                    {t('new_deal_shop_error')}
                                </div>
                            )}
                        </div>
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={locationIcon} className="new-deal__icon" alt="Location Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div className="new-deal__national-location-container">
                                        <div className="new-deal__national-deal-checkbox">
                                            <label htmlFor="nationalDeal"><span className="new-deal__sub-title new-deal__sub-title--centered">{t('new_deal_national_deal')}</span></label>
                                            <input
                                                type="checkbox"
                                                id="nationalDeal"
                                                checked={isNationalDeal}
                                                onChange={handleNationalDealChange}
                                                className="new-deal__national-deal-input"
                                            />
                                        </div>
                                        <div className={`new-deal__location-container ${isNationalDeal ? 'new-deal__location-container--disabled' : ''}`}>
                                            <label htmlFor="locationDropdown"><span className="new-deal__sub-title">{t('new_deal_province')}</span></label>
                                            <select
                                                id="locationDropdown"
                                                value={locationDropdown}
                                                onChange={handleLocationDropdownChange}
                                                disabled={isNationalDeal}
                                                className={locationError ? 'new-deal__input--error' : ''}
                                            >
                                                <option value="">{t('new_deal_select_location')}</option>
                                                {PROVINCES.map((province, index) => (
                                                    <option key={index} value={province}>
                                                        {province}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'location' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'location' && (
                                            <p>{t('new_deal_location_info')}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {locationError && (
                                <div className="new-deal__error-message-box">
                                    {t('new_deal_location_error')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>{t('new_deal_back')}</button>
                    <button type="button" onClick={handleNextStep}>{t('new_deal_next')}</button>
                </div>
            </>
        );
    };

    // Function to render step 3
    const renderStep3 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">{t('new_deal_step3_title')}</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>{t('new_deal_step3_description')}</p><br />
                        <div className="new-deal__field-container">
                            {descriptionLengthError && (
                                <div className="new-deal__error-message-box">
                                    {t('new_deal_description_length_error')}
                                </div>
                            )}
                            {images.map((image, index) => {
                                const key = image ? `image-${index}-${image.lastModified}` : `empty-${index}`;
                                return (
                                    index === 0 || images[index - 1] ? (
                                        <ImageDropZone 
                                            key={key}
                                            onImageDrop={file => handleImageDrop(file, index)} 
                                            previewUrl={image ? URL.createObjectURL(image) : null} 
                                            existingFileNames={uploadedFileNames} // Pass the file names list
                                            requireImageError={attemptedNextWithoutImage && images.every(img => img === null)} // Pass the error when user want to reach next step without at least one image
                                        />
                                    ) : null
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>{t('new_deal_back')}</button>
                    <button type="button" onClick={handleNextStep}>{t('new_deal_next')}</button>
                </div>
            </>
        );
    };
    
    const renderStep4 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">{t('new_deal_step4_title')}</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>{t('new_deal_step4_description')}</p><br />
                        <div className="new-deal__field-container">
                            {descriptionLengthError && (
                                <div className="new-deal__error-message-box">
                                    {t('new_deal_description_length_error')}
                                </div>
                            )}
                            <div className="new-deal__description-box-container">
                                <DescriptionBox
                                    value={dealData.description}
                                    onChange={handleDescriptionChange}
                                />
                            </div>
                            <br /><br />
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>{t('new_deal_back')}</button>
                    <button type="button" onClick={handleNextStep}>{t('new_deal_next')}</button>
                </div>
            </>
        );
    };    
    
    const renderStep5 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">{t('new_deal_step5_title')}</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <div className="new-deal__field-container">
                            <div className="new-deal__sub-field-container">
                                <div className="new-deal__field-container">
                                    <div className="new-deal__input-container">
                                        <img src={startDateIcon} className="new-deal__icon" alt={t('new_deal_start_date_icon')} />
                                        <div className="new-deal__sub-input-container">
                                            <label htmlFor="start_date"><strong>{t('new_deal_start_date')}</strong></label>
                                            <input
                                                id="start_date"
                                                name="start_date"
                                                type="date"
                                                value={dealData.start_date}
                                                onChange={handleChange}
                                                required
                                                min={todayDate}
                                                max={maxDate}
                                            />
                                            {dealData.start_date && (
                                                <>
                                                    <label htmlFor="start_time"><strong>{t('new_deal_start_time')}</strong></label>
                                                    <input
                                                        id="start_time"
                                                        name="start_time"
                                                        type="time"
                                                        value={dealData.start_time}
                                                        onChange={handleChange}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="new-deal__field-container">
                                    <div className="new-deal__input-container">
                                        <img src={endDateIcon} className="new-deal__icon" alt={t('new_deal_end_date_icon')} />
                                        <div className="new-deal__sub-input-container">
                                            <label htmlFor="end_date"><strong>{t('new_deal_end_date')}</strong></label>
                                            <input
                                                id="end_date"
                                                name="end_date"
                                                type="date"
                                                value={dealData.end_date}
                                                onChange={handleChange}
                                                min={dealData.start_date || todayDate}
                                                max={maxDate}
                                                className={dateComparisonError ? 'new-deal__input--error' : ''}
                                            />
                                            {dealData.end_date && (
                                                <>
                                                    <label htmlFor="end_time"><strong>{t('new_deal_end_time')}</strong></label>
                                                    <input
                                                        id="end_time"
                                                        name="end_time"
                                                        type="time"
                                                        value={dealData.end_time}
                                                        onChange={handleChange}
                                                        className={dateComparisonError ? 'new-deal__input--error' : ''}
                                                    />
                                                </>
                                            )}
                                            {dateComparisonError && (
                                                <div className="new-deal__error-message-box">
                                                    {t('new_deal_date_comparison_error')}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="new-deal__field-container">
                            {categoryError && (
                                <div className="new-deal__error-message-box">
                                    {t('new_deal_category_error')}
                                </div>
                            )}
                            <label><strong>{t('new_deal_category')}</strong></label>
                                <div className="new-deal__category-buttons">
                                    {CATEGORIES.map(category => (
                                        <button
                                            key={category.id}
                                            type="button"
                                            className={`new-deal__category-button ${selectedCategoryKey === category.translationKey ? 'selected' : ''}`}
                                            onClick={() => handleDealCategorySelection(category)}  // Pass the category object
                                        >
                                            <img src={category.icon} alt={`${t(category.translationKey)} icon`} className="new-deal__category-icon" />
                                            {t(category.translationKey)}  {/* Display the translated category name */}
                                        </button>
                                    ))}
                                </div>
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>{t('new_deal_back')}</button>
                    <button type="button" onClick={handleNextStep}>{t('new_deal_next')}</button>
                </div>
            </>
        );
    };
    
    const renderStep6 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">{t('new_deal_step6_title')}</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>{t('new_deal_step6_description')}</p><br />
                        <div className="new-deal__deal-summary">
                            <div className="new-deal__content-container-sumup">
                                <strong>{t('new_deal_link')}</strong><br />
                                {dealData.link || <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>{t('new_deal_title')}</strong><br />
                                {dealData.title || <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}<br />
                                <strong>{t('new_deal_current_price')}</strong><br />
                                {dealData.discounted_price || <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}<br />
                                <strong>{t('new_deal_regular_price')}</strong><br />
                                {dealData.regular_price || <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}<br />
                                <strong>{t('new_deal_promo_code')}</strong><br />
                                {dealData.promo_code || <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}<br />
                                <strong>{t('new_deal_shop')}</strong><br />
                                {dealData.shop || <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}<br />
                                <strong>{t('new_deal_location')}</strong><br />
                                {dealData.location || <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>{t('new_deal_start_date')}</strong><br />
                                {dealData.start_date ? (
                                    `${dealData.start_date}${dealData.start_time ? ` ${t('new_deal_at')} ${dealData.start_time}` : ''}`
                                ) : (
                                    <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>
                                )}<br />
                                <strong>{t('new_deal_end_date')}</strong><br />
                                {dealData.end_date ? (
                                    `${dealData.end_date}${dealData.end_time ? ` ${t('new_deal_at')} ${dealData.end_time}` : ''}`
                                ) : (
                                    <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>
                                )}<br />
                                <strong>{t('new_deal_category')}</strong><br />
                                {selectedCategoryKey ? t(CATEGORIES.find(category => category.translationKey === selectedCategoryKey)?.translationKey) : <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>}
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>{t('new_deal_images')}</strong><br />
                                <div className="new-deal__image-container">
                                    {images.map((image, index) => (
                                        <div key={index} className="new-deal__image-box">
                                            {image ? (
                                                <img src={URL.createObjectURL(image)} alt={`${t('new_deal_image_alt')} ${index + 1}`} />
                                            ) : (
                                                <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>{t('new_deal_description')}</strong><br />
                                {dealData.description ? (
                                    <div dangerouslySetInnerHTML={{ __html: dealData.description }}></div>
                                ) : (
                                    <span className="new-deal__empty-data">{t('new_deal_empty_data')}</span>
                                )}
                            </div>
                        </div>
                        <div className="new-deal__rules-box">
                            <p><strong>{t('new_deal_rules_title')}</strong></p>
                            <ol>
                                <li>{t('new_deal_rules_1')}</li>
                                <li>{t('new_deal_rules_2')}</li>
                                <li>{t('new_deal_rules_3')}</li>
                                <li>{t('new_deal_rules_4')}</li>
                                <li>{t('new_deal_rules_5')}</li>
                                <li>{t('new_deal_rules_6')}</li>
                            </ol>
                            <p>{t('new_deal_accept_terms')} <a href="/terms-and-conditions" target="_blank">{t('new_deal_terms_conditions')}</a></p> {/* Translated Text */}
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>{t('new_deal_back')}</button>
                    <button type="button" onClick={handleAddDealClick}>{t('new_deal_submit')}</button>
                </div>
            </>
        );
    };

    return (
        <div className="new-deal">
            <Container isMarginlessOnSmallScreen={true}>
                <div className="new-deal__boxes">
                    <form id="dealForm" onKeyDown={handleKeyDown}>
                        {renderCurrentStep()}
                    </form>
                </div>
            </Container>
        </div>
    );
}