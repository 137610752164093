import CategoryVideoGames from '../assets/category_header_icons/gamepad.svg';
import CategoryHighTech from '../assets/category_header_icons/computer.svg';
import CategoryGrocery from '../assets/category_header_icons/grocery-cart.svg';
import CategoryFashion from '../assets/category_header_icons/dress.svg';
import CategoryCosmetics from '../assets/category_header_icons/cosmetics.svg';
import CategoryFamily from '../assets/category_header_icons/toys.svg';
import CategoryHome from '../assets/category_header_icons/furniture.svg';
import CategoryAutomotive from '../assets/category_header_icons/motorcycle.svg';
import CategoryEntertainment from '../assets/category_header_icons/movie.svg';
import CategorySports from '../assets/category_header_icons/sports.svg';
import CategoryFinance from '../assets/category_header_icons/salary.svg';
import CategoryTravel from '../assets/category_header_icons/travel-luggage.svg';
import CategoryBuilding from '../assets/category_header_icons/settings.svg';

export const CATEGORIES = [
    { id: 1, icon: CategoryVideoGames, translationKey: 'category_video_games' },
    { id: 2, icon: CategoryHighTech, translationKey: 'category_high_tech' },
    { id: 3, icon: CategoryGrocery, translationKey: 'category_grocery' },
    { id: 4, icon: CategoryFashion, translationKey: 'category_fashion' },
    { id: 5, icon: CategoryCosmetics, translationKey: 'category_cosmetics' },
    { id: 6, icon: CategoryFamily, translationKey: 'category_family' },
    { id: 7, icon: CategoryHome, translationKey: 'category_home' },
    { id: 8, icon: CategoryAutomotive, translationKey: 'category_automotive' },
    { id: 9, icon: CategoryEntertainment, translationKey: 'category_entertainment' },
    { id: 10, icon: CategorySports, translationKey: 'category_sports' },
    { id: 11, icon: CategoryFinance, translationKey: 'category_finance' },
    { id: 12, icon: CategoryTravel, translationKey: 'category_travel' },
    { id: 13, icon: CategoryBuilding, translationKey: 'category_building' }
];