//components/AlertPopup.js

/*Values:
error
informative
success
*/
import React from 'react';
import './AlertPopup.css';

const AlertPopup = ({ message, type, onClose }) => {
    return (
        <div className={`alert-popup alert-popup--${type}`} onClick={onClose}>
            {message}
        </div>
    );
};

export default AlertPopup;