// components/NotFoundPage.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const NotFoundPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Use the translation hook

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/'); // Redirect to homepage
        }, 3000); // 3000 milliseconds = 3 seconds

        return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }, [navigate]);

    return (
        <div>
            <h1>{t('not_found_page_title')}</h1> {/* Translated Title */}
            <p>{t('not_found_page_message')}</p> {/* Translated Message */}
        </div>
    );
};

export default NotFoundPage;