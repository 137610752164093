import React, { useState, useEffect, useContext, useRef } from 'react';
import MenuModal from '../components/MenuModal';
import LoginModal from '../components/LoginModal';
import { AuthContext } from '../contexts/AuthContext';
import Container from '../components/Container';
import { useLocation } from 'react-router-dom';
import './Header.css';
import { useTranslation } from 'react-i18next';

import logo from '../assets/logo-dealio.svg';
import loginIcon from '../assets/login_icon.svg';
import newDealIcon from '../assets/global-icons/add.svg';
import menuIcon from '../assets/global-icons/header_menu.svg';

function Header() {
    const { t } = useTranslation();
    const { setIsAuthenticated, setUserName, isModalOpen, toggleModal, userName, checkAuthentication } = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [headerVisible, setHeaderVisible] = useState(true);
    const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
    const [isAuthChecked, setIsAuthChecked] = useState(false); // Track if backend auth check is completed
    const [isLocallyAuthenticated, setIsLocallyAuthenticated] = useState(false); // Track initial local storage status
    const [isLocalCheckDone, setIsLocalCheckDone] = useState(false); // Track if local storage check is done
    const location = useLocation();
    const userButtonRef = useRef(null);

    // Check local storage for authentication status and user info
    useEffect(() => {
        const localAuthStatus = localStorage.getItem('isAuthenticated');
        const localUserName = localStorage.getItem('userName');

        // Check local storage first and set the initial state based on it
        if (localAuthStatus) {
            setIsLocallyAuthenticated(JSON.parse(localAuthStatus));
            setUserName(localUserName);
        }

        // Mark that the local check is done
        setIsLocalCheckDone(true);

        // Then asynchronously check the backend for the true auth status
        checkAuthentication().then(() => {
            setIsAuthChecked(true);  // Backend check completed
        });
    }, [checkAuthentication, setIsAuthenticated, setUserName]);

    const handleAccountFocus = () => {
        setIsMenuModalOpen(!isMenuModalOpen);
    };

    const handleLogoClick = (e) => {
        e.preventDefault();
        window.location.href = '/';
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            window.location.href = `/search?name=${encodeURIComponent(searchQuery)}`;
        }
    };

    const getInitials = (name) => {
        return name ? name.charAt(0).toUpperCase() : '';
    };

    return (
        <div className="header__header-space">
            <header className={`header ${headerVisible ? 'header--visible' : 'header--hidden'}`}>
                <Container isMarginlessOnSmallScreen={true} isHeader={true}>
                    <div className="header__content">
                        <div className="header__logo" onClick={handleLogoClick}>
                            <a href="/">
                                <img src={logo} alt={t('header_logo_alt')} width="50" />
                            </a>
                        </div>

                        <form className="header__search-form" onSubmit={handleSearchSubmit}>
                            <input
                                type="text"
                                className="header__search-input"
                                placeholder={t('header_search_placeholder')}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </form>

                        {/* Show the Add Deal button only if on homepage or deal page */}
                        {(location.pathname === '/' || location.pathname.startsWith('/deal')) && (
                            <button
                                className="header__new-deal-button--bigScreen"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (isLocallyAuthenticated) {
                                        window.location.href = '/newdeal';
                                    } else {
                                        toggleModal(true);
                                    }
                                }}>
                                <img src={newDealIcon} alt={t('header_new_deal_alt')} className="header__icon" />
                                {t('header_add_deal')}
                            </button>
                        )}

                        {/* Do not show anything until the local storage check is done */}
                        {isLocalCheckDone && (
                            isLocallyAuthenticated ? (
                                <button
                                    className="header__menu-button header__logout-button"
                                    onClick={handleAccountFocus}
                                    ref={userButtonRef}
                                >
                                    {getInitials(userName)}
                                </button>
                                ) : (
                                    <>
                                        <button className="header__login-button header__connect-bigScreen" onClick={toggleModal}>
                                            <img src={loginIcon} alt={t('header_login_alt')} className="header__icon" />
                                            <span>{t('header_connect')}</span>
                                        </button>
                                        {/* Menu button to the right of the connect button */}
                                        <button
                                            className="header__menu-button"
                                            onClick={() => setIsMenuModalOpen(!isMenuModalOpen)}
                                            ref={userButtonRef}
                                        >
                                            <img src={menuIcon} alt={t('header_menu_alt')} />
                                        </button>
                                    </>
                                )
                        )}

                        {isMenuModalOpen && (
                            <MenuModal
                                onClose={handleAccountFocus}
                                position={{
                                    top: userButtonRef.current ? userButtonRef.current.getBoundingClientRect().bottom + window.scrollY : 0,
                                    left: userButtonRef.current ? userButtonRef.current.getBoundingClientRect().left + window.scrollX : 0,
                                }}
                            />
                        )}
                    </div>
                </Container>

                {/* Only render the login modal when not authenticated and modal is open */}
                {(!isLocallyAuthenticated && isModalOpen) && <LoginModal onClose={toggleModal} />}
            </header>

            {/* Button for small screens */}
            {location.pathname === '/' && (
                <button
                    className="header__new-deal-button--smallScreen"
                    onClick={(e) => {
                        e.preventDefault();
                        if (isLocallyAuthenticated) {
                            window.location.href = '/newdeal';
                        } else {
                            toggleModal(true);
                        }
                    }}>
                    <img src={newDealIcon} alt={t('header_new_deal_alt')} className="header__newDeal-icon--smallScreen" />
                </button>
            )}
        </div>
    );
}

export default Header;