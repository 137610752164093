import React, { useState, useEffect, useRef } from 'react';
import apiService from '../services/api';
import Container from '../components/Container';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import './AdminReports.css'; 

export default function AdminReports() {
    const [deals, setDeals] = useState([]);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const limit = 4;
    const loadBeforeReachBottom = 2000;
    const alreadyFetched = useRef(new Set());
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDealReports, setSelectedDealReports] = useState([]);

    useEffect(() => {
        fetchDeals();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - loadBeforeReachBottom && !loading && hasMore) {
                fetchDeals();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    const fetchDeals = async () => {
        if (loading || alreadyFetched.current.has(skip)) return;
        setLoading(true);
        alreadyFetched.current.add(skip);
        try {
            console.log("fetch deals: false");
            const fetchedDeals = await apiService.getAllDealsAdmin(limit, skip, null, null);
            setDeals(prevDeals => [...prevDeals, ...fetchedDeals]);
            setSkip(prevSkip => prevSkip + limit);
            setHasMore(fetchedDeals.length === limit);

            console.log("fetch deals:", fetchedDeals);
        } catch (error) {
            console.error("Failed to fetch deals:", error.message);
            if (error.message === 'Forbidden access') {
                window.location.href = '/';
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (dealId) => {
        try {
            const result = await apiService.deleteDealClick(dealId);
            alert(`${result.message}: ${result.deletedDealId}`);
            setDeals(deals.filter(deal => deal._id !== dealId));
        } catch (error) {
            console.error("Failed to delete deal:", error);
        }
    };

    const createSlug = (title) => {
        if (!title) {
            return '';
        }
        return title
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .toLowerCase();
    };

    const openReportsModal = async (dealId) => {
        try {
            const reports = await apiService.getAllReportsForDeal(dealId);
            setSelectedDealReports(reports);
            setModalIsOpen(true);
        } catch (error) {
            console.error("Failed to fetch reports:", error);
        }
    };

    const setDealStatus = async (dealId, status) => {
        try {
            const result = await apiService.updateDealStatus(dealId, status);
            alert(result.message);
        } catch (error) {
            console.error(`Failed to update deal status to ${status}:`, error);
        }
    };

    return (
        <div className="admin">
            <Container>
                <div className="admin__boxes">
                    <section className="admin__intro-section">
                        <h1>Admin Reports page</h1>
                        <Link to="/admin/deal_validation">Go to Admin Deals Validation</Link>
                    </section>
    
                    <section className="admin__display-deals-section">
                        {deals.map(deal => (
                            <div key={deal._id} className="admin__deal-box">
                                <div className="admin__deal-box-description">
                                    <Link to={`/deal/${createSlug(deal.title)}/${deal._id}`}>
                                        <b>ID:</b> {deal._id || 'Empty'}
                                    </Link>
                                    <br />
                                    <b>Title:</b> {deal.title || 'Empty'}
                                    <br />
                                    <b>Link:</b> {deal.link || 'Empty'}
                                    <br />
                                    <b>Discounted Price:</b> {deal.discounted_price || 'Empty'} &rarr; <b>Regular Price:</b> {deal.regular_price || 'Empty'}
                                    <br />
                                    <b>Category:</b> {deal.category || 'Empty'}
                                    <br />
                                    <b>Location:</b> {deal.location || 'Empty'}
                                    <br />
                                    <b>Promo Code:</b> {deal.promo_code || 'Empty'}
                                    <br />
                                    <b>Shop:</b> {deal.shop || 'Empty'}
                                    <br />
                                    <b>User ID:</b> {deal.user_id || 'Empty'}
                                    <br />
                                    <b>Start Date:</b> {deal.start_date || 'Empty'} - <b>End Date:</b> {deal.end_date || 'Empty'}
                                    <br />
                                    <b>Status:</b> {deal.status || 'Empty'}
                                    <br />
                                    <b>Description:</b> <div dangerouslySetInnerHTML={{ __html: deal.description || 'Empty' }}></div>
                                    <br />
                                    <b>Date Registering:</b> {deal.date_registering || 'Empty'}
                                    <br />
                                    {deal.image1_compressed && <img src={deal.image1_compressed} alt="Deal Image 1 compressed" className="admin__deal-box-image" />}
                                    {deal.image2 && <img src={deal.image2} alt="Deal Image 2" className="admin__deal-box-image" />}
                                    {deal.image3 && <img src={deal.image3} alt="Deal Image 3" className="admin__deal-box-image" />}
                                    <br />
                                    <div className="admin__deal-box-controls">
                                        <button onClick={() => handleDelete(deal._id)}>Delete Deal</button>
                                        <button onClick={() => setDealStatus(deal._id, 'Expired')}>Expired</button>
                                        <button onClick={() => setDealStatus(deal._id, 'Cancelled')}>Cancelled</button>
                                        <button onClick={() => setDealStatus(deal._id, 'Valid')}>Valid</button>
                                    </div>
                                </div>
                                <div className="admin__deal-box-report">
                                    <h3>Report Counts</h3>
                                    <table className="admin__report-table">
                                        <thead>
                                            <tr>
                                                <th>Report Type</th>
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(deal.report_counts || {}).map(([reportType, count]) => (
                                                <tr key={reportType}>
                                                    <td>{reportType}</td>
                                                    <td>{count}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <button onClick={() => openReportsModal(deal._id)}>View Reports</button>
                                </div>
                            </div>
                        ))}
                    </section>
                </div>
            </Container>
    
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Deal Reports"
                appElement={document.getElementById('root')}
            >
                <h2>Reports for Deal</h2>
                <table>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Type</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedDealReports.map((report, index) => (
                            <tr key={index}>
                                <td>{report.user_id}</td>
                                <td>{report.report_type}</td>
                                <td>{report.comment}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button onClick={() => setModalIsOpen(false)}>Close</button>
            </Modal>
        </div>
    );
}