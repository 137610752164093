// /constants/ProfanityLists.js

export const profanityList_en = [
    // Political Activities and Ideologies
    'anarchy',
    'authoritarian',
    'coup',
    'dictator',
    'extremist',
    'fascism',
    'hate speech',
    'jihad',
    'kkk',
    'nazism',
    'propaganda',
    'terrorism',
    'white supremacy',
    'communism',
    'marxism',
    'leninism',
    'socialism',
    'capitalism',
    'imperialism',

    // Vietnam-Specific Historical and Political Topics
    'south vietnam',
    'north vietnam',
    'viet cong',
    'ho chi minh trail',
    'viet minh',
    'ngo dinh diem',
    'saigon regime',
    '1975 fall',
    'vietnam war',
    'paris peace accords',

    // Names of Political Figures (Global and Vietnam-Specific)
    'ho chi minh',
    'ngo dinh diem',
    'mao zedong',
    'stalin',
    'hitler',
    'musolini',
    'lenin',
    'trump',
    'biden',
    'putin',
    'zelenskyy',

    // Sensitive Topics Related to Ethnicities and Movements
    'white supremacy',
    'racism',
    'apartheid',
    'genocide',
    'holocaust',
    'zionism',
    'black lives matter',
    'antifa',
    'neo-nazi',
    'kkk',

    // Sensitive Words Related to Conflict and Independence
    'independence movement',
    'rebellion',
    'separatist',
    'revolutionary',
    'guerrilla warfare',
    'militia',
    'insurgency',

    // Terms Related to Global Controversial Conflicts
    'taiwan independence',
    'hong kong protests',
    'tiananmen square',
    'uyghur',
    'tibet independence',
    'south china sea dispute',
    'spratly islands',
    'paracel islands',
    'china-vietnam war',

    // Illegal Activities
    'assault',
    'blackmail',
    'bribe',
    //'counterfeit',
    'extortion',
    //'fraud',
    'hacking',
    'illegal',
    'laundering',
    //'piracy',
    //'scam',
    'smuggle',
    'theft',

    // Drugs
    'adderall',
    'cocaine',
    'crack',
    'ecstasy',
    'fentanyl',
    'hash',
    'heroin',
    'ketamine',
    'lsd',
    'marijuana',
    'methamphetamine',
    'morphine',
    'opium',
    'oxycodone',
    'pot',
    'roxy',
    'weed',
    'xanax',

    // Counterfeit or Illegal Goods
    //'black market',
    //'fake',
    //'forged',
    //'gray market',
    //'knockoff',
    //'replica',
    //'unlicensed',

    // Prostitution and Related Terms
    'brothel',
    'escort',
    //'john',
    'pimp',
    'prostitute',
    //'red light',
    'sex work',
    //'solicitation',

    // Weapons and Arms
    'ammunition',
    'blade',
    'bomb',
    'dagger',
    'drone',
    'explosive',
    'grenade',
    'gun',
    'machete',
    'missile',
    'pistol',
    'rifle',
    'tank'
];

export const profanityList_vn = [
    // Hoạt động và ý thức hệ chính trị
    'vô chính phủ',
    'độc tài',
    'đảo chính',
    'lãnh tụ',
    'cực đoan',
    'phát xít',
    'ngôn từ kích động thù hận',
    'thánh chiến',
    'kkk',
    'đức quốc xã',
    'tuyên truyền',
    'khủng bố',
    'chủ nghĩa thượng đẳng trắng',
    'chủ nghĩa cộng sản',
    'chủ nghĩa mác',
    'chủ nghĩa lê nin',
    'chủ nghĩa xã hội',
    'chủ nghĩa tư bản',
    'chủ nghĩa đế quốc',

    // Chủ đề lịch sử và chính trị Việt Nam
    'miền nam việt nam',
    'miền bắc việt nam',
    'việt cộng',
    'đường mòn hồ chí minh',
    'việt minh',
    'ngô đình diệm',
    'chế độ sài gòn',
    'sự kiện 1975',
    'chiến tranh việt nam',
    'hiệp định paris',

    // Tên nhân vật chính trị (Toàn cầu và Việt Nam)
    'hồ chí minh',
    'ngô đình diệm',
    'mao trạch đông',
    'stalin',
    'hitler',
    'musolini',
    'lênin',
    'trump',
    'biden',
    'putin',
    'zelensky',

    // Chủ đề nhạy cảm liên quan đến sắc tộc và phong trào
    'chủ nghĩa thượng đẳng trắng',
    'phân biệt chủng tộc',
    'chế độ apartheid',
    'diệt chủng',
    'holocaust',
    'chủ nghĩa phục quốc',
    'phong trào black lives matter',
    'antifa',
    'tân phát xít',
    'kkk',

    // Từ liên quan đến xung đột và độc lập
    'phong trào độc lập',
    'nổi loạn',
    'ly khai',
    'cách mạng',
    'chiến tranh du kích',
    'dân quân',
    'nổi dậy',

    // Thuật ngữ liên quan đến xung đột toàn cầu
    'độc lập đài loan',
    'biểu tình hồng kông',
    'quảng trường thiên an môn',
    'người uyghur',
    'độc lập tây tạng',
    'tranh chấp biển đông',
    'quần đảo trường sa',
    'quần đảo hoàng sa',
    'chiến tranh trung quốc việt nam',

    // Hoạt động bất hợp pháp
    'tấn công',
    'tống tiền',
    'hối lộ',
    'cưỡng đoạt',
    'hack',
    'bất hợp pháp',
    'rửa tiền',
    'buôn lậu',
    'trộm cắp',

    // Ma túy
    'adderall',
    'cocaine',
    'crack',
    'ecstasy',
    'fentanyl',
    'hash',
    'heroin',
    'ketamine',
    'lsd',
    'marijuana',
    'methamphetamine',
    'morphine',
    'opium',
    'oxycodone',
    'pot',
    'roxy',
    'weed',
    'xanax',

    // Hàng giả hoặc hàng bất hợp pháp
    'chợ đen',
    'hàng giả',
    'hàng nhái',
    'sao chép',
    'hàng không phép',

    // Mại dâm và các thuật ngữ liên quan
    'nhà thổ',
    'gái gọi',
    'tú bà',
    'mại dâm',
    'công việc tình dục',

    // Vũ khí và trang bị
    'đạn dược',
    'dao',
    'bom',
    'dao găm',
    'máy bay không người lái',
    'thuốc nổ',
    'lựu đạn',
    'súng',
    'dao rựa',
    'tên lửa',
    'súng ngắn',
    'súng trường',
    'xe tăng',

    // Buồi and its variants
    'buồi',
    'buoi',
    'dau buoi',
    'daubuoi',
    'caidaubuoi',
    'nhucaidaubuoi',
    'dau boi',
    'bòi',
    'dauboi',
    'caidauboi',
    'đầu bòy',
    'đầu bùi',
    'dau boy',
    'dauboy',
    'caidauboy',
    'b`',

    // Cặc and its variants
    'cặc',
    'cak',
    'kak',
    'kac',
    'cac',
    'concak',
    'nungcak',
    'bucak',
    'caiconcac',
    'caiconcak',
    'cu',
    'cặk',
    'dái',
    'giái',
    'zái',
    'kiu',

    // Cứt and its variants
    'cứt',
    'cuccut',
    'cutcut',
    'cứk',
    'cuk',
    'cười ỉa',
    'cười ẻ',

    // Đéo and its variants
    'đéo',
    'đếch',
    'đếk',
    'dek',
    'đết',
    'đệt',
    'đách',
    'dech',
    'đ\'',
    'deo',
    'd\'',
    'đel',
    'đél',
    'del',
    'dell ngửi',
    'dell ngui',
    'dell chịu',
    'dell chiu',
    'dell hiểu',
    'dell hieu',
    'dellhieukieugi',
    'dell nói',
    'dell noi',
    'dellnoinhieu',
    'dell biết',
    'dell biet',
    'dell nghe',
    'dell ăn',
    'dell an',
    'dell được',
    'dell duoc',
    'dell làm',
    'dell lam',
    'dell đi',
    'dell di',
    'dell chạy',
    'dell chay',
    'deohieukieugi',

    // Địt and its variants
    'địt',
    'đm',
    'dm',
    'đmm',
    'dmm',
    'đmmm',
    'dmmm',
    'đmmmm',
    'dmmmm',
    'đmmmmm',
    'dmmmmm',
    'đcm',
    'dcm',
    'đcmm',
    'dcmm',
    'đcmmm',
    'dcmmm',
    'đcmmmm',
    'dcmmmm',
    'đệch',
    'đệt',
    'dit',
    'dis',
    'diz',
    'đjt',
    'djt',
    'địt mẹ',
    'địt mịe',
    'địt má',
    'địt mía',
    'địt ba',
    'địt bà',
    'địt cha',
    'địt con',
    'địt bố',
    'địt cụ',
    'dis me',
    'disme',
    'dismje',
    'dismia',
    'dis mia',
    'dis mie',
    'đis mịa',
    'đis mịe',
    'ditmemayconcho',
    'ditmemay',
    'ditmethangoccho',
    'ditmeconcho',
    'dmconcho',
    'dmcs',
    'ditmecondi',
    'ditmecondicho',

    // Đù (đủ) má and its variants
    'đụ',
    'đụ mẹ',
    'đụ mịa',
    'đụ mịe',
    'đụ má',
    'đụ cha',
    'đụ bà',
    'đú cha',
    'đú con mẹ',
    'đú má',
    'đú mẹ',
    'đù cha',
    'đù má',
    'đù mẹ',
    'đù mịe',
    'đù mịa',
    'đủ cha',
    'đủ má',
    'đủ mẹ',
    'đủ mé',
    'đủ mía',
    'đủ mịa',
    'đủ mịe',
    'đủ mie',
    'đủ mia',
    'đìu',
    'đờ mờ',
    'đê mờ',
    'đờ ma ma',
    'đờ mama',
    'đê mama',
    'đề mama',
    'đê ma ma',
    'đề ma ma',
    'dou',
    'doma',
    'duoma',
    'dou má',
    'duo má',
    'dou ma',
    'đou má',
    'đìu má',
    'á đù',
    'á đìu',
    'đậu mẹ',
    'đậu má',

    // Đĩ and its variants
    'đĩ',
    'di~',
    'đuỹ',
    'điếm',
    'cđĩ',
    'cdi~',
    'đilol',
    'điloz',
    'đilon',
    'diloz',
    'dilol',
    'dilon',
    'condi',
    'condi~',
    'dime',
    'di me',
    'dimemay',
    'condime',
    'condimay',
    'condimemay',
    'con di cho',
    'con di cho\'',
    'condicho',
    'bitch',
    'biz',
    'bít chi',
    'con bích',
    'con bic',
    'con bíc',
    'con bít',
    'phò',

    // Lồn and its variants
    'lồn',
    'l`',
    'loz',
    'lìn',
    'nulo',
    'ml',
    'matlon',
    'cailon',
    'matlol',
    'matloz',
    'thml',
    'thangmatlon',
    'thangml',
    'đỗn lì',
    'tml',
    'thml',
    'diml',
    'dml',
    'hãm',
    'xàm lol',
    'xam lol',
    'xạo lol',
    'xao lol',
    'con lol',
    'ăn lol',
    'an lol',
    'mát lol',
    'mat lol',
    'cái lol',
    'cai lol',
    'lòi lol',
    'loi lol',
    'ham lol',
    'củ lol',
    'cu lol',
    'ngu lol',
    'tuổi lol',
    'tuoi lol',
    'mõm lol',
    'mồm lol',
    'mom lol',
    'như lol',
    'nhu lol',
    'nứng lol',
    'nung lol',
    'nug lol',
    'nuglol',
    'rảnh lol',
    'ranh lol',
    'đách lol',
    'dach lol',
    'mu lol',
    'banh lol',
    'tét lol',
    'tet lol',
    'vạch lol',
    'vach lol',
    'cào lol',
    'cao lol',
    'tung lol',
    'mặt lol',
    'mát lol',
    'mat lol'
];