//components/ImageDropZone.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import for translations
import { useDropzone } from 'react-dropzone';
import './ImageDropZone.css';
import closeIcon from '../assets/global-icons/close.svg'; // Import the close icon

function ImageDropZone({ onImageDrop, previewUrl, existingFileNames, requireImageError }) {
    const { t } = useTranslation(); // Use the translation hook
    const [isFileTooLarge, setIsFileTooLarge] = useState(false);
    const [isInvalidFormat, setIsInvalidFormat] = useState(false);
    const [isImageTooSmall, setIsImageTooSmall] = useState(false);
    const [isDuplicateFile, setIsDuplicateFile] = useState(false);
    const [preview, setPreview] = useState(previewUrl);

    useEffect(() => {
        setPreview(previewUrl);
    }, [previewUrl]);

    const onDrop = (acceptedFiles, fileRejections) => {
        setIsFileTooLarge(false);
        setIsInvalidFormat(false);
        setIsImageTooSmall(false);
        setIsDuplicateFile(false);

        const fileTooLarge = fileRejections.some(rejection => 
            rejection.errors.some(error => error.code === 'file-too-large')
        );
        if (fileTooLarge) {
            setIsFileTooLarge(true);
            return;
        }

        const invalidFormat = fileRejections.some(rejection => 
            rejection.errors.some(error => error.code === 'file-invalid-type')
        );
        if (invalidFormat) {
            setIsInvalidFormat(true);
            return;
        }

        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];

            const isDuplicate = existingFileNames.includes(file.name);

            if (isDuplicate) {
                setIsDuplicateFile(true);
                return;
            }

            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                if (image.width < 250 || image.height < 250) {
                    setIsImageTooSmall(true);
                } else {
                    onImageDrop(file);
                    setPreview(URL.createObjectURL(file));
                }
            };
        }
    };

    const handleDeleteImage = (e) => {
        e.stopPropagation();
        setPreview(null);
        onImageDrop(null);
    };

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/webp': ['.webp'],
            'image/heic': ['.heic'],
        },
        maxSize: 6000000,
    });

    const className = [
        'image-drop-zone',
        isFocused ? 'image-drop-zone--focused' : '',
        isDragAccept ? 'image-drop-zone--accept' : '',
        (isDragReject || isFileTooLarge) ? 'image-drop-zone--reject' : ''
    ].join(' ');

    const showError = () => {
        return isFileTooLarge || isInvalidFormat || isImageTooSmall || isDuplicateFile || requireImageError;
    };

    const getErrorMessage = () => {
        if (isFileTooLarge) return t('image_dropzone_file_too_large'); // Translated message
        if (isInvalidFormat) return t('image_dropzone_invalid_format'); // Translated message
        if (isImageTooSmall) return t('image_dropzone_image_too_small'); // Translated message
        if (isDuplicateFile) return t('image_dropzone_duplicate_file'); // Translated message
        if (requireImageError) return t('image_dropzone_require_image_error'); // Translated message
        return "";
    };

    return (
        <div className="image-drop-zone__container">
            {showError() && (
                <div className="image-drop-zone__error-message-box visible">
                    <p>{getErrorMessage()}</p>
                </div>
            )}
            <div {...getRootProps()} className={className}>
                <input {...getInputProps()} />
                {preview ? (
                    <div className="image-drop-zone__image-preview">
                        <img src={preview} alt={t('image_dropzone_preview_alt')} className="image-drop-zone__preview-img" />
                        <button className="image-drop-zone__delete-image" onClick={handleDeleteImage}>
                            <img src={closeIcon} alt={t('image_dropzone_delete_alt')} />
                        </button>
                    </div>
                ) : (
                    <>
                        <p>{t('image_dropzone_drag_drop_text')}</p>
                        <p className="image-drop-zone__info">{t('image_dropzone_accepted_formats')}</p>
                    </>
                )}
            </div>
        </div>
    );
}

export default ImageDropZone;